import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getRoleAPI } from "../../api/private/getRole";
import { precheckAPI } from "../../api/public/preCheckLoginAPI";
//@ts-ignore
import Cookies from 'js-cookie';

type loginArgs = Record<string, string>;

export const fetchLogin: any = createAsyncThunk(
    'login/fetchLogin',
    async (params: loginArgs, { rejectWithValue }) => {
        const {
            email,
            password,
            otpCode,
            rememberMe = false
        } = params;
        try {
            const { data } = await axios.post('/api/auth/jwt/signin', { email, password, otpCode, rememberMe },
                {
                    headers: {
                        Language: 'ru'
                    }
                }
            )
            return data;
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const fetchPrecheck: any = createAsyncThunk(
    'login/precheck',
    async (params: loginArgs, { rejectWithValue }) => {
        const {
            email,
            password,
        } = params;
        try {
            const data = await precheckAPI(email, password)
            localStorage.setItem('email', email);
            localStorage.setItem('mfacode', data.payload.mfaCode);
            localStorage.setItem('preToken', data.payload.preToken);
            localStorage.setItem('mfaUrl', data.payload.mfaUrl);
            return data;
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getRole: any = createAsyncThunk(
    'login/fetchRole',
    async () => {
        const { data } = await getRoleAPI();
        return data;
    }
)


interface IRegistration {
    email: string,
    password: string,
    otpCode: string,
    token: any,
    role: string,
    error: any,
    toBuy: boolean,
    resetPassword?: string,
    resetComparePassword?: string,
    resetMailCode?: string,
    resetOtpCode?: string,
    rememberMe: boolean
}

const initialState: IRegistration = {
    email: "",
    password: "",
    otpCode: "",
    token: Cookies.get('token'),
    role: "",
    error: {},
    toBuy: false,
    resetPassword: '',
    resetComparePassword: '',
    resetMailCode: '',
    resetOtpCode: '',
    rememberMe: false
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setOtpCode: (state, action) => {
            state.otpCode = action.payload;
        },
        setRememberMe: (state, action) => {
            state.rememberMe = action.payload;
        },
        setClearState: (state) => {
            state.email = "";
            state.password = "";
            state.otpCode = "";
            state.token = "";
        },
        setLoginError: (state, action) => {
            state.error = action.payload
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setToken: (state, action) => {
            Cookies.set('token', action.payload);
            state.token = action.payload;
        },
        setToBuy: (state, action) => {
            state.toBuy = action.payload;
        },
        setResetPassword: (state, action) => {
            state.resetPassword = action.payload
        },
        setResetComparePassword: (state, action) => {
            state.resetComparePassword = action.payload
        },
        setResetEmailCode: (state, action) => {
            state.resetMailCode = action.payload
        },
        setResetOtpCode: (state, action) => {
            state.resetOtpCode = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLogin.pending, (state) => {
        })
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            state.token = action.payload.payload.token;
            state.role = action.payload.payload.role;
            if (state.rememberMe === true) {
                Cookies.set('token', action.payload.payload.token, { expires: 1 / 4 });
            }
            else {
                Cookies.set('token', action.payload.payload.token, { expires: 1 / 8 });
            }
            state.password = "";
            state.otpCode = "";
        })
        builder.addCase(fetchLogin.rejected, (state) => {
        })
        builder.addCase(getRole.pending, (state) => {
        })
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.role = action.payload.payload.role;
        })
        builder.addCase(getRole.rejected, (state) => {
        })
    },
})

export const { setClearState, setEmail, setPassword, setRememberMe, setOtpCode, setLoginError, setRole,
    setToken, setToBuy, setResetPassword, setResetComparePassword, setResetEmailCode, setResetOtpCode } = loginSlice.actions;

export default loginSlice.reducer