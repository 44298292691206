import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { sendEmailCode, setQRcode, verifyAccount, setEmailCode } from "../../redux/slices/registrationSlice";
import { setActive } from "../../redux/slices/snackbarSlice";
import { unwrapResult } from '@reduxjs/toolkit';
import { setRole, setToken } from "../../redux/slices/loginSlice";
//@ts-ignore
import Cookies from 'js-cookie';

import { enqueueSnackbar } from 'notistack';

const Confirm = () => {

    const navigate = useNavigate()

    const { email, emailCode, QRcode, preToken } = useSelector((state: any) => state.registration)
    //const { toBuy } = useSelector((state: any) => state.login)

    const [enter, setEnter] = useState(false)

    const dispatch = useDispatch()

    const sendToEmail = () => {
        dispatch(sendEmailCode(preToken))
            .then(unwrapResult)
            .then((originalPromiseResult: any) => {
                enqueueSnackbar('Message successfully sended', { variant: 'success' });
            })
            .catch((data: any) => {
                enqueueSnackbar('Failed to send message', { variant: 'error' });
            })
        setEnter(true)
    }

    interface IVerify {
        message: string;
        payload: {
            role: string;
            token: string
        },
        status: number | string;
    }

    const verify = () => {
        dispatch(verifyAccount({ preToken, QRcode, emailCode }))
            .then(unwrapResult)
            .then((data: IVerify) => {
                dispatch(setRole(data.payload.role));
                dispatch(setToken(data.payload.token));
                navigate('/choosesub')
            })
            .catch((error: any) => {

                if (error.status === 500) {
                    enqueueSnackbar('Server error', { variant: 'error' });
                }
                else if (error.status === 409) {
                    enqueueSnackbar(error.message ? error.message
                        : 'Registration failed', { variant: 'error' });
                }
                else {
                    if (error) {
                        for (let i = 0; i < error.payload.length; i++) {
                            enqueueSnackbar(error.payload[i].error ? error.payload[i].error
                                : 'Registration failed', { variant: 'error' });
                        }
                    }
                }
            })
    }

    return (
        <div className="confirm">
            <h1 className="auth__title auth-form__title--confirm">Confirm your data</h1>
            <form onSubmit={(e) => e.preventDefault()} className="auth-form" autoComplete="false">
                <label>
                    <p className="auth-form__title">Code from your Email:</p>
                    <div className="input-wrapper input-wrapper--login input-wrapper--confirm">
                        {!enter ? (
                            <>
                                <input value={email ? email : localStorage.getItem('email')}
                                       className="auth-form__input" type="text"/>
                                <button onClick={() => sendToEmail()} className="btn-reset confirm-btn">Send</button>
                            </>
                        ) : (
                            <>
                                <input
                                    value={emailCode}
                                    onChange={(e) => dispatch(setEmailCode(e.target.value))}
                                    placeholder="Enter your code"
                                    className="auth-form__input auth-form__input--colored" type="text"/>
                                <button tabIndex={-1} onClick={() => setEnter(true)}
                                        className="btn-reset confirm-btn confirm-btn--enterCode"><span>Send</span>
                                </button>
                            </>
                        )}
                    </div>
                </label>
                {/*<label>*/}
                {/*    <p className="auth-form__title">Code from 2FA app:</p>*/}
                {/*    <div className="input-wrapper input-wrapper--password">*/}
                {/*        <input value={QRcode} onChange={(e) => dispatch(setQRcode(e.target.value))} className="auth-form__input" type="text" />*/}
                {/*    </div>*/}
                {/*</label>*/}
                <p className="auth-form__descr">If you didn't receive the email, please check the spam folder</p>
                <div className="btn-group">
                    <button onClick={() => verify()} className="btn-reset auth__btn auth__btn--filled">Continue</button>
                    <button onClick={() => navigate('/login')} className="btn-reset auth__btn">Back</button>
                </div>
            </form>
        </div>
    )
}


export default Confirm;