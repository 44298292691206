import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAcitveSub } from "../../api/private/getActiveSub";


export const getSubs: any = createAsyncThunk(
    'login/fetchGetSubs',
    async () => {
        const data = await getAcitveSub();
        return data;
    }
)

interface ISubscription {
    subs: [{
        createdAt: number,
        id: number,
        subscriptionPlan: {
            bestPlan: boolean,
            id: number,
            paymentPeriod: number,
            periodType: string,
            price: number,
            subscriptionType: string,

        },
        validUntil: number
    }] | [],
    loading: string;
}

const initialState: ISubscription = {
    subs: [],
    loading: 'loading'
}

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setCancelSubsById: (state, action) => {
            state.subs = action.payload
            //@ts-ignore
            //state.subs = state.subs.filter()
        },
        setCancelAll: (state) => {
            state.subs = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSubs.pending, (state) => {
            state.loading = 'true'
        })
        builder.addCase(getSubs.fulfilled, (state, action) => {
            state.subs = action.payload.payload;
            state.loading = 'false'
        })
        builder.addCase(getSubs.rejected, (state) => {
            state.loading = 'false'
        })
    },
})

export const { setCancelSubsById, setCancelAll } = subscriptionSlice.actions;

export default subscriptionSlice.reducer