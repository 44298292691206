import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

type Props = {
    subscriptionType: string;
    option1?: string;
    option2?: string;
    option3?: string;
    listTitle?: string;
    priceText?: string;
    oldPriceText?: string;
    btnText?: string;
    lang?: string | any;
    plans: any;
    btnFunc: (id: number | string) => void;
    btnRef?: any;
}


const Rate = ({ plans, listTitle, option1, option2, option3, priceText, oldPriceText, btnText, btnFunc, btnRef }: Props) => {
    const [header, setHeader] = useState()
    const [price, setPrice] = useState()
    const [oldPrice, setOldPrice] = useState()
    const [descr, setDescr] = useState()
    const [features, setFeatures] = useState<any>([])
    const [subscriptionType, setSubscriptionType] = useState()
    const [subId, setSubId] = useState<any>()
    const [params, setParams] = useState({
        paymentPeriod: 1,
        periodType: "MONTHLY",
    })

    const { subs } = useSelector((state: any) => state.subscriptions)

    const [matchSub, setMatchSub] = useState(false)

    useEffect(() => {
        searchPlan(plans, params)
    }, [plans, params])


    useEffect(() => {
        if (subs.map((sub: any) => sub.subscriptionPlan.id).includes(subId)) {
            setMatchSub(true)
        } else {
            setMatchSub(false)
        }
    }, [subId, subs])


    const searchPlan = (plans: any, params: any) => {
        let trial = plans.find((x: any) => x.subscriptionType === "TRIAL")
        if (trial) {
            setHeader(trial?.planName)
            setPrice(trial?.price)
            setFeatures(trial?.features)
            setDescr(trial?.planDescription)
            setOldPrice(trial?.previousPrice)
            setSubscriptionType(trial.subscriptionType)
            setSubId(trial.id)
        }
        else {
            let obj = plans.find((x: any) => (x.periodType === params.periodType) && (x.paymentPeriod === params.paymentPeriod))
            setHeader(obj?.planName)
            setPrice(obj?.price)
            setFeatures(obj?.features)
            setDescr(obj?.planDescription)
            setOldPrice(obj?.previousPrice)
            setSubscriptionType(obj.subscriptionType)
            setSubId(obj.id)
        }
    }


    if (!features) {
        return <LoadingSpinner />
    }

    return (
        <div className="rates__card card">
            <div className={`card__header card__header--${subscriptionType}`}><article className="card__article">{header}</article></div>
            <div className="card__descr">{descr}</div>
            <ul className="list-reset card__list">
                {features.map((item: any, index: any) => (
                    <li key={index} className="card__item">{item}</li>
                ))}
            </ul>
            {subscriptionType === 'TRIAL' ? (
                <button onClick={() => btnFunc(subId)} className="btn btn-reset card__button">{btnText}</button>
            ) : (
                <>
                    <p className="card__support">{listTitle}</p>
                    <ul className="list-reset price-list">
                        <form>
                            <li className="price-list__item price">
                                <label className={"price__title"}>
                                    <input defaultChecked={true} className="price__checkbox" type="radio"
                                           name="checkbox" onClick={() => {
                                        setParams({
                                            "paymentPeriod": 1,
                                            "periodType": "MONTHLY",
                                        })
                                    }}/>
                                    <span className="custom-checkbox"></span>
                                    {option1}
                                </label>
                            </li>
                            <li className="price-list__item price">
                                <label className={"price__title"}>
                                    <input className="price__checkbox" type="radio" name="checkbox" onClick={() => {
                                        setParams({
                                            "paymentPeriod": 6,
                                            "periodType": "MONTHLY",
                                        })
                                    }}/>
                                    <span className="custom-checkbox"></span>
                                    {option2}
                                </label>
                            </li>
                            <li className="price-list__item price">
                                <label className={"price__title"}>
                                    <input className="price__checkbox" type="radio" name="checkbox" onClick={() => {
                                        setParams({
                                            "paymentPeriod": 1,
                                            "periodType": "ANNUALLY",
                                        })
                                    }}/>
                                    <span className="custom-checkbox"></span>
                                    {option3}
                                </label>
                            </li>
                        </form>
                    </ul>
                    <button ref={btnRef ? btnRef : null} onClick={() => btnFunc(subId)}
                            className={`btn btn-reset card__button card__button--${subscriptionType}`}>{priceText}{price}</button>
                    {/*{!matchSub ? (*/}
                    {/*    <button ref={btnRef ? btnRef : null} onClick={() => btnFunc(subId)} className={`btn btn-reset card__button card__button--${subscriptionType}`}>{priceText}{price}</button>*/}
                    {/*) : (<p className="card__activeSub-descr">Subscription is already active</p>)}*/}
                    {oldPrice ? (<span className="card__old-price">{oldPriceText} ${oldPrice}</span>) : ("")}
                </>
            )}
        </div>
    )
}

export default Rate;