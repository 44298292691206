import React, { useEffect, useState } from "react";
//@ts-ignore
import Cookies from 'js-cookie';
import logo from '../../assets/svg/dashboard/logo-dashboard.svg';

import './Sidebar.scss';
import { useLocation, useNavigate } from "react-router";
import { setClearState } from "../../redux/slices/loginSlice";
import { useDispatch } from "react-redux";
import { useTour } from "@reactour/tour";
import { ReactComponent as Subs } from './../../assets/svg/dashboard/subscriptions.svg';
const Sidebar = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setIsOpen } = useTour()
    const location: any = useLocation()

    const [activeLink, setActiveLink] = useState('0');

    const logout = () => {
        dispatch(setClearState());
        Cookies.remove('token')
        //localStorage.removeItem('token');
        navigate('/login');
    }

    const handleNavigate = (route: string, e: any) => {
        setActiveLink(e.target.id);
        navigate(route)
    }

    const logoNavigate = () => {
        setActiveLink('1');
        navigate('/')
    }

    useEffect(() => {
        switch (location.pathname) {
            case '/dashboard':
                setActiveLink('1');
                break;
            case '/dashboard/subscriptions':
                setActiveLink('2');
                break;
            case '/dashboard/settings':
                setActiveLink('3');
                break;
            case '/dashboard/telegram':
                setActiveLink('4');
                break;
        }
    }, [activeLink])


    return (
        <div className="sidebar">
            <div className="sidebar__content">
                <div className="sidebar__header" onClick={logoNavigate}>
                    <img src={logo} alt="kaisospeak logo" className="sidebar__logo" />
                </div>
                <ul className="list-reset sidebar__list">
                    <li id='1' className={activeLink == '1' ? 'sidebar__item sidebar__item--active' : 'sidebar__item'}
                        onClick={(e) => handleNavigate('/dashboard', e)}>
                        Notifications
                    </li>
                    <li id='2' className={activeLink == '2' ? 'sidebar__item sidebar__item--active' : 'sidebar__item'}
                        onClick={(e) => handleNavigate('/dashboard/subscriptions', e)}>
                        <Subs className={activeLink == '2' ? "subs subs--active" : "subs"} />Subscriptions</li>
                    <li id='3' className={activeLink == '3' ? 'sidebar__item sidebar__item--active' : 'sidebar__item'}
                        onClick={(e) => handleNavigate('./settings', e)}>Settings</li>
                    <li id='4' className={activeLink == '4' ? 'sidebar__item sidebar__item--active' : 'sidebar__item'}
                        onClick={(e) => handleNavigate('./telegram-bot', e)}>Telegram Bot</li>
                </ul>
                <div onClick={() => logout()} className="sidebar__logout">
                    <a href="#" className="sidebar__link">Log Out</a>
                </div>
                {location.pathname === '/dashboard' && (<div onClick={() => setIsOpen(true)} className="app-guide">
                    <span className="app-guide__btn">?</span>
                </div>)}
            </div>
        </div>
    )
}

export default Sidebar;