import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
//@ts-ignore
import Cookies from 'js-cookie';

const ProtectedRoute = () => {
    let auth = { token: Cookies.get('token') };
    return auth.token && auth.token !== "undefined" ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;