import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { useSelector } from "react-redux";
import Snackbar from "../../components/snackbar/Snackbar";
import { setActive } from "../../redux/slices/snackbarSlice";
import { useDispatch } from "react-redux";

import { enqueueSnackbar } from 'notistack';


const AuthLayout = () => {

    const { status } = useSelector((state: any) => state.registration)
    const { token } = useSelector((state: any) => state.login)
    const dispatch = useDispatch()
    //let auth = { token: localStorage.getItem('token') };
    const navigate = useNavigate()

    useEffect(() => {
        if (token && token !== "undefined" && window.location.pathname !== '/choosesub') {
            navigate('/dashboard')
        }
    }, [token])

    useEffect(() => {
        if (localStorage.getItem('error') === 'token-rotted') {
            // dispatch(setActive({
            //     title: 'Authorization token is rotten',
            //     active: true,
            //     typeAlet: 'warning',
            //     message: 'you must relogin to application'
            // }))
            enqueueSnackbar('You must relogin to application', { variant: 'warning' });
            localStorage.removeItem('error')
        }
    }, [localStorage.getItem('error')])

    return (
        <div className="auth-container">
            <div className="auth">
                <Outlet />
            </div>
            {status === 'loading' ? <LoadingSpinner /> : null}
            <Snackbar />
        </div>
    )
}

export default AuthLayout;