import React, { useEffect } from "react";

import './ActiveSub.scss';
import { useDispatch } from "react-redux";
import { getSubs } from "../../redux/slices/subscriptionSlice";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CloseIcon from "../closeIcon/CloseIcon";
import { useTranslation } from "react-i18next";

const ActiveSub = ({ handleDeleteByID, openAlertCancelAll }: any) => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation<any>('');

    const { subs, loading } = useSelector((state: any) => state.subscriptions)
    const { status } = useSelector((state: any) => state.registration)

    useEffect(() => {
        if (status === '' || status === 'success') {
            dispatch(getSubs())
        }
    }, [status])


    const getLocaleDateTimeString = (date: any) => {
        date = JSON.stringify(date).replace('.', '');
        if (date.length === 12) {
            date = date + '0';
        }
        if (date.length > 12) {
            date = date.substr(0, 13);
        }
        date = new Date(JSON.parse(date));
        return date.toISOString().slice(0, 10).replace('T', ' ');
    };

    if (subs.length < 1 && loading === 'false') {
        return (
            <div className="active-sub">
                <article className="active-sub__article">Active subscriptions</article>
                <div className="active-sub__item">
                    <div className={`active-sub__header`}>
                        Try it
                    </div>
                    <div className="active-sub__content">
                        <div className="active-sub__descr active-sub__descr--no-border">
                            There are currently no active subscriptions
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="active-sub">
            <article className="active-sub__article">Active subscriptions</article>
            {loading !== 'true' ?
                <>
                    {subs.map((item: any, index: any) => (
                        <div key={index} className="active-sub__item">
                            <div className={`active-sub__header active-sub__header--${item.subscriptionPlan.subscriptionType}`}>
                                {item.subscriptionPlan.subscriptionType}
                                {/* <img src={closeIcon} className="active-sub__cancel" alt='cancel subscription' onClick={() => handleDeleteByID(item.id)} /> */}
                                <CloseIcon functionClose={() => handleDeleteByID(item.id)} type="danger" />
                            </div>
                            <div className="active-sub__content">
                                <div className="active-sub__descr">
                                    {item.subscriptionPlan.subscriptionType === 'TRIAL' && (
                                        <> {t('activeSub.TRIAL')}</>
                                    )}
                                    {item.subscriptionPlan.subscriptionType === 'BASIC' && (
                                        <> {t('activeSub.BASIC')}</>
                                    )}
                                    {item.subscriptionPlan.subscriptionType === 'PREMIUM' && (
                                        <> {t('activeSub.PREMIUM')}</>
                                    )}
                                </div>
                                <div className="active-sub__date">
                                    <span className="active-sub__date-from">From - <p>{getLocaleDateTimeString(item.createdAt)}</p>
                                    </span>
                                    <span className="active-sub__date-to">To - <p>{getLocaleDateTimeString(item.validUntil)}</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </> :
                <>
                    <SkeletonTheme>
                        <div className="active-sub__item" style={{ overflow: 'hidden' }}>
                            <Skeleton height={270} width={500} />
                        </div>
                    </SkeletonTheme>
                </>}
            <div className="active-sub__cancel cancel">
                <article className="cancel__article">Cancel all active subscriptions</article>
                <div className="cancel__descr">
                    Your current active plan would be immidiatly cancelled. If you want to continue use the app you can wait untill the active subscription expired
                </div>
                <button onClick={() => openAlertCancelAll()} className="btn-reset cancel__button">Cancel</button>
            </div>
        </div>
    )
}

export default ActiveSub;