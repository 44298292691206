import React, { useState } from "react";

import { clearForm, fetchRegistration, setEmail, setError, setInvalidPasswords, setPassword, setSecondPassword, setUsername } from "../../redux/slices/registrationSlice";
import { useSelector } from "react-redux";
import { setActive } from "../../redux/slices/snackbarSlice";
import { validate } from "../../utils/validate";
import CustomInput from "../customInput/CustomInput";
import { comparePass } from "../../utils/comparePass";

import CloseIcon from "../closeIcon/CloseIcon";

import { enqueueSnackbar } from 'notistack';


interface IRegistrationForm {
    dispatch: (func: any) => any;
    toggleForm: () => any;
    navigate: (link: string) => any;
}



const RegistrationForm: React.FC<IRegistrationForm> = ({ dispatch, toggleForm, navigate }) => {


    const { error, email, username, password, secondPassword } = useSelector((state: any) => state.registration)
    const registration = async () => {
        if (comparePass(password, secondPassword)) {
            dispatch(setInvalidPasswords(comparePass(password, secondPassword)))
            enqueueSnackbar('Password missmatch', { variant: 'error' });
        } else {
            dispatch(fetchRegistration({ email, username, password }))
                .unwrap()
                .then(() => {
                    navigate('/confirm')
                    localStorage.setItem('email', email)
                })
                .catch((rejectedValueOrSerializedError: any) => {
                    if (rejectedValueOrSerializedError.status === 500) {
                        enqueueSnackbar('Server error', { variant: 'error' });
                    } else {
                        for (let i = 0; i < rejectedValueOrSerializedError.payload.length; i++) {
                            enqueueSnackbar(rejectedValueOrSerializedError.payload[i].error ? rejectedValueOrSerializedError.payload[i].error
                                : 'Registration failed', { variant: 'error' });
                        }
                    }
                    dispatch(setError(validate(rejectedValueOrSerializedError.payload)));
                })
        }
    }

    const closeRegistration = () => {
        navigate('/')
        dispatch(clearForm());
    }

    return (
        <>
            <h1 className="auth__title">sign up</h1>
            <form className="auth-form">
                <CloseIcon functionClose={() => closeRegistration()} />
                <CustomInput
                    inputTitle="Email:"
                    inputValue={email}
                    setInputValue={setEmail}
                    inputType="email"
                    error={error?.email}
                    dispatch={dispatch}
                    autoComplete='email'
                    name='email'
                />
                <CustomInput
                    inputTitle="Username:"
                    inputValue={username}
                    setInputValue={setUsername}
                    inputType="text"
                    error={error?.username}
                    autoComplete='off'
                    dispatch={dispatch} />
                <CustomInput
                    inputTitle="Password:"
                    inputValue={password}
                    setInputValue={setPassword}
                    inputType="password"
                    error={error?.password}
                    dispatch={dispatch}
                    autoComplete='off'
                    name='password'
                    passwordsCompare={error?.compare} />
                <CustomInput
                    inputTitle="Confirm your password:"
                    inputValue={secondPassword}
                    setInputValue={setSecondPassword}
                    inputType="password"
                    error={error?.password}
                    autoComplete='off'
                    name='password'
                    dispatch={dispatch}
                    passwordsCompare={error?.compare} />
                <label>
                    <p onClick={() => dispatch(toggleForm())} className="auth-form__link">Already have an account?</p>
                </label>
            </form>
            <div className="btn-group">
                <button onClick={registration} className="btn-reset auth__btn auth__btn--filled" type="submit">Sign Up
                </button>
            </div>
        </>
    )
}

export default RegistrationForm;