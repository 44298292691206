import React, { useEffect, useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import ActiveSub from "../../components/activeSub/ActiveSub";

import './SettingsPage.scss';
import Modal from "../../components/modal/Modal";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { sendEmailCode, setInvalidPasswords } from "../../redux/slices/registrationSlice";
import { unwrapResult } from '@reduxjs/toolkit';

// import CloseIcon from './../../assets/svg/dashboard/close--filled.svg';
import { setEmail, setPassword, setNewSecondPassword, setNewEmail, setNewPassword, setOtpCode, setEmailCode, setCredsChangeError, getCreds, changeCreds } from "../../redux/slices/accountSettings";
import Alert from "../../components/alert/Alert";
import { setCancelAll, setCancelSubsById } from "../../redux/slices/subscriptionSlice";
import { cancelSubById } from "../../api/private/cancelSubById";
import { deleteAcount } from "../../api/private/deleteAccount";
import CustomInput from "../../components/customInput/CustomInput";
import { cancelAllAPI } from "../../api/private/cancelAll";
//@ts-ignore
import Cookies from 'js-cookie';
import { setRole, setToken } from "../../redux/slices/loginSlice";

import { enqueueSnackbar } from 'notistack';
import CloseIcon from "../../components/closeIcon/CloseIcon";
import { sendAuthMailAPI } from "../../api/private/sendAuthMailAPI";
import { comparePass } from "../../utils/comparePass";
import { useTranslation } from "react-i18next";
import { validate } from "../../utils/validate";

const SettingsPage = () => {

    const { t, i18n } = useTranslation<any>('')
    const navigate = useNavigate()

    const { email, error, password, newSecondPassword, newEmail, newPassword, emailCode, otpCode } = useSelector((state: any) => state.accountSettings)

    const { subs } = useSelector((state: any) => state.subscriptions)

    const [enter, setEnter] = useState(false)

    const dispatch = useDispatch()

    const [questionTest, setQuestionText] = useState('')

    useEffect(() => {
        dispatch(getCreds())
    }, [])

    const sendToEmail = () => {
        dispatch(sendEmailCode())
            .then(unwrapResult)
            .then((originalPromiseResult: any) => {
                enqueueSnackbar(originalPromiseResult.payload ? originalPromiseResult.payload : 'Message successfully sended', { variant: 'success' });
            })
            .catch((data: any) => {
                enqueueSnackbar('Failed to send message', { variant: 'error' });
            })
        setEnter(true)
    }

    const confirmChange = () => {
        dispatch(changeCreds({ 'email': newEmail, 'password': newPassword, emailCode, otpCode }))
            .unwrap()
            .then(() => {
                enqueueSnackbar('Account settings are successfully changed', { variant: 'success' });
                dispatch(setToken(''))
                dispatch(setRole(''))
                Cookies.remove('token')
                window.location.reload();
            })
            .catch((rejectedValueOrSerializedError: any) => {
                if (rejectedValueOrSerializedError.payload) {
                    for (let i = 0; i < rejectedValueOrSerializedError.payload.length; i++) {
                        enqueueSnackbar(rejectedValueOrSerializedError.payload[i].error ? rejectedValueOrSerializedError.payload[i].error
                            : 'Error on changing credentials', {variant: 'error'});
                    }
                } else {
                    enqueueSnackbar(rejectedValueOrSerializedError.message ? rejectedValueOrSerializedError.message
                        : 'Error on changing credentials', {variant: 'error'});
                }
                dispatch(setCredsChangeError(validate(rejectedValueOrSerializedError.payload)));
            })
        dispatch(setOtpCode(''));
        dispatch(setEmailCode(''));
        setEnter(false);
    }

    const [modal, setModal] = useState<boolean>(false);
    const [toggleModal, setToggleModal] = useState('');
    const [lastModal, setLastModal] = useState('')
    const [prevModal, setPrevModal] = useState('')
    const [alertAccount, setAlertAccount] = useState<boolean>(false);
    const [alertSub, setAlertSub] = useState<boolean>(false)

    const [idToDelete, setIdToDelete] = useState<any>()

    const handleDeleteByID = (id: any) => {
        setAlertSub(true);
        setIdToDelete(id);
    }

    const deleteSubsById = async () => {
        dispatch(setCancelSubsById(subs.filter((sub: any) => sub.subscriptionPlan === idToDelete)));
        dispatch(setRole(''));
        const data = await cancelSubById(idToDelete);
        setAlertSub(false)
        window.location.reload();
    }

    const deleteUser = () => {
        setAlertAccount(false)
        deleteAcount()
            .then(() => {
                Cookies.remove('token')
                dispatch(setToken(''))
                navigate('/login')
            })
    }

    const handleToggleModal = (modal: string): void => {
        setToggleModal(modal);
        if (modal === 'confirm') {
            setLastModal(modal)
        } else {
            setPrevModal(modal)
        }
        setModal(true)
        setEnter(false);
    }

    const handleChangeCredsPopup = (modal : string): void => {
        dispatch(setNewEmail(undefined))
        dispatch(setNewPassword(undefined))
        dispatch(setNewSecondPassword(undefined))
        handleToggleModal(modal);
    }

    const [alertCancelAll, setAlertCancelAll] = useState<boolean>(false)

    const openAlertCancelAll = () => {
        setAlertCancelAll(!alertCancelAll)
    }

    const deleteAllSubs = async () => {
        dispatch(setCancelAll());
        dispatch(setRole(''));
        const data = await cancelAllAPI()
        setAlertCancelAll(false)
        window.location.reload();
    }

    const sendAuthMail = () => {
        sendAuthMailAPI(email, questionTest)
            .then((data) => {
                enqueueSnackbar(data.message, { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(err.response.data.message, { variant: 'error' });
            })
    }

    const [errors, setErrors] = useState({})
    const [passwordsCompare, setPasswordCompare] = useState<any>()

    const precheckModalForm = () => {
        if (prevModal == 'password') {
            if (!newPassword || !newSecondPassword) {
                enqueueSnackbar('Password is empty', {variant: 'error'});
                setPasswordCompare({error: 'Password is empty'})
            } else if (comparePass(newPassword, newSecondPassword)) {
                enqueueSnackbar('Password mismatch', {variant: 'error'});
                setPasswordCompare({error: 'Password mismatch'})
            } else {
                handleToggleModal('confirm')
                setPasswordCompare('')
            }
        } else {
            if (!newEmail) {
                enqueueSnackbar('Email is empty', {variant: 'error'});
                setPasswordCompare('Email is empty')
            } else {
                handleToggleModal('confirm')
                setPasswordCompare('')
            }
        }
    }


    return (
        <>
            <div className="settings-page">
                <Navigation />
                <div className="settings-page__content">
                    <div className="settings-page__item">
                        <article className="settings-page__article">Credentials:</article>
                        <div className="credentials">
                            <form>
                                <p className="contact-support__descr">
                                    If you want to change your credentials, please use the form below:
                                </p>
                                <label>
                                    <p className="custom-input_title credentials__title">Email:</p>
                                    <div className='input-wrapper credentials__input credentials__input--mail'>
                                        <input
                                            className="custom-input__input" defaultValue={email} disabled
                                            autoComplete="off"/>
                                    </div>
                                </label>
                                <label>
                                    <p className="custom-input_title credentials__title">Password:</p>
                                    <div className='input-wrapper credentials__input credentials__input--password'>
                                        <input
                                            className="custom-input__input" type="password" placeholder="********"
                                            defaultValue={password} autoComplete="off" disabled/>
                                    </div>
                                </label>
                            </form>
                            {/*<button onClick={() => handleChangeCredsPopup('email')}*/}
                            {/*        className="btn-reset settings-page__button settings-page__button--filled settings-page__button_first">Change email*/}
                            {/*</button>*/}
                            <button onClick={() => handleChangeCredsPopup('password')}
                                    className="btn-reset settings-page__button settings-page__button--filled settings-page__button_second">Change password
                            </button>
                        </div>
                    </div>
                    <div className="settings-page__item">
                        <article className="settings-page__article">Contact support:</article>
                        <div className="contact-support">
                            <p className="contact-support__descr">
                                If you have any question regarding how does the app  works or any problems with getting access, please contact support:
                            </p>
                            <article className="contact-support__contact contact-support__contact--mail">kairospeak@supoprt.com</article>
                            <article className="contact-support__contact contact-support__contact--telegram">@kairospeak</article>
                            <p className="contact-support__descr">
                                If you have a suggestion on any issue, you can leave a request and our managers will contact you.
                            </p>
                            <textarea value={questionTest} onChange={(e) => setQuestionText(e.target.value)} className="contact-support__textarea"></textarea>
                            <button onClick={sendAuthMail} className="btn-reset settings-page__button settings-page__button--filled">Send</button>
                        </div>
                    </div>
                </div>
                <div className="settings-page__item settings-page__item--bottom">
                    <p className="settings-page__descr">On account deletion you’d loose the access even if you have active subscription</p>
                    <button onClick={() => setAlertAccount(true)} className="btn-reset settings-page__button settings-page__button--danger">Delete account</button>
                </div>
            </div>
            <ActiveSub handleDeleteByID={handleDeleteByID} openAlertCancelAll={openAlertCancelAll} />
            {modal && <Modal>
                {toggleModal === 'email' ? (
                    <div className={lastModal === 'confirm' ? "creds-change creds-change--animation" : "creds-change"}>
                        <CloseIcon functionClose={() => setModal(false)} />
                        <h1 className="creds-change__title">Email change</h1>
                        <form action="#" autoComplete="off">
                            <CustomInput
                                inputTitle="Enter new email:"
                                inputValue={newEmail}
                                setInputValue={setNewEmail} inputType="login"
                                error={error?.newEmail}
                                dispatch={dispatch}
                                autoComplete={'off'} />
                        </form>
                        <div className="creds-change__btns btn-group">
                            <button onClick={precheckModalForm} className="btn-reset confirm__btn confirm__btn--filled">Continue</button>
                            <button onClick={() => setModal(false)} className="btn-reset confirm__btn confirm__btn">Back</button>
                        </div>
                    </div>
                ) : toggleModal == 'password' ? (
                    <div className={lastModal === 'confirm' ? "creds-change creds-change--animation" : "creds-change"}>
                        <CloseIcon functionClose={() => setModal(false)} />
                        <h1 className="creds-change__title">Password change</h1>
                        <form action="#" autoComplete="off">
                            <CustomInput
                                inputTitle="Enter new password:"
                                inputValue={newPassword}
                                setInputValue={setNewPassword} inputType="password"
                                error={error?.newPassword}
                                dispatch={dispatch}
                                autoComplete={'off'} />
                            <CustomInput
                                inputTitle="Confirm new password:"
                                inputValue={newSecondPassword}
                                setInputValue={setNewSecondPassword} inputType="password"
                                error={error?.newSecondPassword}
                                dispatch={dispatch}
                                autoComplete={'off'} />
                        </form>
                        <div className="creds-change__btns btn-group">
                            <button onClick={precheckModalForm} className="btn-reset confirm__btn confirm__btn--filled">Continue</button>
                            <button onClick={() => setModal(false)} className="btn-reset confirm__btn confirm__btn">Back</button>
                        </div>
                    </div>
                ) : (
                    <div className="confirm creds-change__confirm">
                        <CloseIcon functionClose={() => setModal(false)} />
                        <h1 className="confirm__title confirm-form__title">Confirm your data</h1>
                        <form onSubmit={(e) => e.preventDefault()} className="confirm-form">
                            <label>
                                <p className="confirm-form__article">Code from your Email:</p>
                                <div className="input-wrapper input-wrapper--login input-wrapper--confirm">
                                    {!enter ? (
                                        <>
                                            <input value={email} className="confirm-form__input" type="text" />
                                            <button onClick={() => sendToEmail()} className="btn-reset confirm-btn">Send</button>
                                        </>
                                    ) : (
                                        <>
                                            <input value={emailCode} onChange={(e) => dispatch(setEmailCode(e.target.value))} placeholder="Enter your code" className="confirm-form__input" type="text" />
                                            <button tabIndex={-1} onClick={() => setEnter(true)} className="btn-reset confirm-btn confirm-btn--enterCode"><span>Send</span></button>
                                        </>
                                    )}
                                </div>
                            </label>
                            {/*<label>*/}
                            {/*    <p className="confirm-form__article">Code from 2FA app:</p>*/}
                            {/*    <div className="input-wrapper input-wrapper--password">*/}
                            {/*        <input value={otpCode} onChange={(e) => dispatch(setOtpCode(e.target.value))} className="confirm-form__input" type="text" />*/}
                            {/*    </div>*/}
                            {/*</label>*/}
                            <div className="btn-group">
                                <button onClick={confirmChange} className="btn-reset confirm__btn confirm__btn--filled">Change credentials</button>
                                <button onClick={() => handleToggleModal(prevModal)} className="btn-reset confirm__btn confirm__btn">Back</button>
                            </div>
                        </form>
                    </div>
                )}
            </Modal>}
            {alertAccount &&
                <Alert>
                    {/* <img onClick={() => setAlertAccount(false)} src={CloseIcon} alt="close alert" className="confirm__img" /> */}
                    <CloseIcon functionClose={() => setAlertAccount(false)} />
                    <h2 className="alert__title">Warning</h2>
                    <p className="alert__descr">Deleting your account is irreversible. All subscriptions and data will be permanently lost.</p>
                    <button className="btn-reset alert__btn" onClick={() => deleteUser()}>Delete account</button>
                </Alert>}
            {alertSub &&
                <Alert>
                    {/* <img onClick={() => setAlertSub(false)} src={CloseIcon} alt="close alert" className="confirm__img" /> */}
                    <CloseIcon functionClose={() => setAlertSub(false)} />
                    <h2 className="alert__title">Cancel subscription?</h2>
                    <p className="alert__descr">Are you sure you want to cancel this subscription package?
                        It will not be possible to undo this action. Current subscription will be deactivated</p>
                    <button className="btn-reset alert__btn" onClick={() => deleteSubsById()}>Cancel</button>
                </Alert>}
            {alertCancelAll &&
                <Alert>
                    {/* <img onClick={() => setAlertCancelAll(false)} src={CloseIcon} alt="close alert" className="confirm__img" /> */}
                    <CloseIcon functionClose={() => setAlertCancelAll(false)} />
                    <h2 className="alert__title">Cancel all subscriptions?</h2>
                    <p className="alert__descr">Are you sure you want to cancel this subscription package?
                        It will not be possible to undo this action. Current subscription will be deactivated</p>
                    <button className="btn-reset alert__btn" onClick={() => deleteAllSubs()}>Cancel</button>
                </Alert>}
        </>
    )
}

export default SettingsPage;