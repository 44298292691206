import React from "react";
import { useTranslation } from 'react-i18next';

import './Options.scss'

//import Filter from './../../assets/img/aa.png';
import Filter from './../../assets/img/filter/filterbg.png';

import FilterItem1 from './../../assets/img/filter/filter1.jpg';
import FilterItem2 from './../../assets/img/filter/filter2.jpg';
import FilterItem3 from './../../assets/img/filter/filter3.jpg';
import FilterItem4 from './../../assets/img/filter/filter4.jpg';
import FilterItem5 from './../../assets/img/filter/filter5.jpg';
import Toopltip from "../tooltip/Tooltip";

const Options: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {

    const { i18n } = useTranslation()

    const leftList = t('options.leftlist', { returnObjects: true });
    const rightList = t('options.rightlist', { returnObjects: true });

    const renderBoldText = (text: string) => {
        const parts = text.split(/\*\*(.*?)\*\*/g);
        return parts.map((part, i) => {
            return i % 2 === 0 ? part : <b key={i}>{part}</b>;
        });
    };

    return (
        <section ref={refProp} className={i18n.language === 'en' ? 'options' : 'options options--ru'}>
            <div className="container">
                <h2 className="options__title">{t('options.title')}</h2>
                <h3 className="options__subtitle">{t('options.subtitle')}</h3>
                {/*<p className="options__descr">{t('options.descr')}</p>*/}
                <div className="options__content grid">
                    <div className="options__grid options__grid--left">
                        <ul className="list-reset options__list">
                            {leftList.map((item: any, index: any) => (
                                <li key={index} className="options__item option">
                                    <article className="option__article">{item.article}</article>
                                    <p className="option__descr">{renderBoldText(item.descr)}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="options__grid options__grid--center">
                        <img src={Filter} alt="filter interface" />
                    </div>
                    <div className="options__grid options__grid--right">
                        <ul className="list-reset options__list">
                            {rightList.map((item: any, index: any) => (
                                <li key={index} className="options__item option option--right">
                                    <article className="option__article">{item.article}</article>
                                    <p className="option__descr">{renderBoldText(item.descr)}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="options__mobile">
                    <ul className="list-reset filters">
                        <li className="filters__item filter">
                            <div className="filter__img_container">
                                <img src={FilterItem1} alt="search filter" className="filter__img" />
                            </div>
                            <div className="filter__content">
                                <article className="filter__title">{renderBoldText(t('options.mobile1.article'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile1.descr'))}</p>
                            </div>
                        </li>
                        <li className="filters__item filter">
                            <img src={FilterItem2} alt="choise profit " className="filter__img" />
                            <div className="filter__content">
                                <article className="filter__title">{renderBoldText(t('options.mobile2.article'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile2.descr'))}</p>
                            </div>
                        </li>
                        <li className="filters__item filter">
                            <img src={FilterItem3} alt="amount to spend" className="filter__img" />
                            <div className="filter__content">
                                <article className="filter__title">{renderBoldText(t('options.mobile3.article'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile3.descr'))}</p>
                            </div>
                        </li>
                        <li className="filters__item filter">
                            {/* <img src={FilterItem4} alt="options" className="filter__img" /> */}
                            <div className="filter__content">
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Include commissions
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile4.article1'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile4.descr1'))}</p>
                                <span className="filter__underline"></span>
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Only available to exchange
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile4.article2'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile4.descr2'))}</p>
                                <span className="filter__underline"></span>
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Verified only
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile4.article3'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile4.descr3'))}</p>
                            </div>
                        </li>
                        <li className="filters__item filter">
                            <div className="filter__content">
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Available
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile5.article1'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile5.descr1'))}</p>
                                <span className="filter__underline"></span>
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Available
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile5.article2'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile5.descr2'))}</p>
                                <span className="filter__underline"></span>
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Available
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile5.article3'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile5.descr3'))}</p>
                                <span className="filter__underline"></span>
                                <label className="custom-checkbox filter__checkbox">
                                    <input disabled type="checkbox" className="custom-checkbox__field" />
                                    <span className="custom-checkbox__title">Available
                                        <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                    </span>
                                </label>
                                <article className="filter__title">{renderBoldText(t('options.mobile5.article4'))}</article>
                                <p className="filter__descr">{renderBoldText(t('options.mobile5.descr4'))}</p>
                            </div>
                        </li>
                        {/* <button className="btn-reset filter__btn">Try for free</button> */}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Options;