import React, { useState } from "react";

import './Footer.scss'

import FooterLogo from './../../assets/svg/footer-logo.svg'

import { ReactComponent as Message } from './../../assets/svg/footer/message.svg'
import { ReactComponent as Telegram } from './../../assets/svg/footer/telegram.svg'
import { ReactComponent as Youtube } from './../../assets/svg/footer/youtube.svg'
import { ReactComponent as Twitter } from './../../assets/svg/footer/twitter.svg'

import { createPortal } from "react-dom";
import Modal from "../modal/Modal";
import CloseIcon from "../closeIcon/CloseIcon";
//@ts-ignore
import Pdf from '../../assets/pdf/sample.pdf';

import { enqueueSnackbar } from 'notistack';
import { useDispatch } from "react-redux";
import { setStatus } from "../../redux/slices/registrationSlice";
import { sendMailPublicAPI } from "../../api/public/sendMailAPI";

import { contacts } from "../../utils/contats";

const Footer = ({ t }: any) => {

    const [modalActive, setModalActive] = useState(false);

    const [email, setEmail] = useState('')
    const [descr, setDescr] = useState('')

    const readPrivacy = () => {
        window.open(Pdf)
    }

    const dispatch = useDispatch();

    const handleSendMessage = async () => {
        dispatch(setStatus('loading'));
        try {
            await sendMailPublicAPI(email, descr);
            dispatch(setStatus(''));
            enqueueSnackbar('Message sended', { variant: 'success' });
        } catch (e: any) {
            //dispatch(setStatus('error'));
            dispatch(setStatus(''));
            enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Message sending failed', { variant: 'error' });
        }
    }

    return (
        <footer className="footer">
            <div className="container grid">
                <div className="footer__left">
                    <h3 className="footer__article">{t('footer.article')}</h3>
                    <p className="footer__descr">{t('footer.descr')}</p>
                </div>
                <div className="footer__center">
                    <img src={FooterLogo} alt="footer logo" className="footer__img" />
                    <button onClick={() => setModalActive(true)} className="btn btn-reset footer__btn">{t('footer.btn')}</button>
                </div>
                <div className="footer__right">
                    <ul className="list-reset footer__list social">
                        <li className="footer__item">
                            <a href={`mailto: ${contacts.email}`} className="social__link"> <Message className="social__icon" /></a>
                        </li>
                        <li className="footer__item">
                            <a href={contacts.telegram} target="_blank" className="social__link"><Telegram className="social__icon" /></a>
                        </li>
                        <li className="footer__item">
                            <a href={contacts.youtube} target="_blank" className="social__link"><Youtube className="social__icon" /></a>
                        </li>
                        {/*<li className="footer__item">*/}
                        {/*    <a href={contacts.twitter} target="_blank" className="social__link"><Twitter className="social__icon" /></a>*/}
                        {/*</li>*/}
                    </ul>
                    {/*<p onClick={readPrivacy} className="privacy">Privacy Policy</p>*/}
                </div>
            </div>
            {modalActive && createPortal(
                <Modal>
                    <div className="describe">
                        <CloseIcon functionClose={() => setModalActive(false)} />
                        <h1 className="describe__title">Describe your questions</h1>
                        <div className="input-container">
                            <input placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} className="describe__input" />
                        </div>
                        <div className="textarea-container">
                            <textarea placeholder="describe your questions" value={descr} onChange={(e) => setDescr(e.target.value)} className="describe__textarea"></textarea>
                        </div>
                        <button onClick={() => handleSendMessage()} className="btn-reset describe__button">Send</button>
                    </div>
                </Modal>, document.querySelector('.landing-container')!
            )}
        </footer>
    )
}

export default Footer;