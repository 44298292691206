import React, {useEffect} from "react";

import './Modal.scss';

const Modal = ({ children }: any) => {

     //@ts-ignore
     useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, [])

    return (
        <div className="modal">
            <div className="modal__content">
                {children}
            </div>
        </div>
    )
}

export default Modal