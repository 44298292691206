import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Navigation from "../../components/navigation/Navigation";
import { getRates } from "../../api/public/rates";
import Rate from "../../components/rate/Rate";


import './Subscription.scss';
import ActiveSub from "../../components/activeSub/ActiveSub";
import { useDispatch } from "react-redux";
import { setCancelAll, setCancelSubsById } from "../../redux/slices/subscriptionSlice";
import { useSelector } from "react-redux";
import Alert from "../../components/alert/Alert";
import CloseIcon from './../../assets/svg/dashboard/close--filled.svg';
import { cancelSubById } from "../../api/private/cancelSubById";
import { cancelAllAPI } from "../../api/private/cancelAll";
import { paymentById } from "../../api/private/paymentAPI";
import { setStatus } from "../../redux/slices/registrationSlice";
import { getPaymentStatusByID } from "../../api/private/paymentStatusAPI";
import { setActive } from "../../redux/slices/snackbarSlice";
import { getRole, setRole } from "../../redux/slices/loginSlice";

import { enqueueSnackbar } from 'notistack';
import { useNavigate } from "react-router";


const Subscription = () => {

    const dispatch = useDispatch()
    const { subs } = useSelector((state: any) => state.subscriptions)
    const navigate = useNavigate();

    const btnTrial = useRef()
    const btnBasic = useRef()
    const btnPremium = useRef()


    const [plans, setPlans] = useState<any>([])
    const [alertSub, setAlertSub] = useState<boolean>(false)
    const [idToDelete, setIdToDelete] = useState<any>()

    const [alertCancelAll, setAlertCancelAll] = useState<boolean>(false)

    const openAlertCancelAll = () => {
        setAlertCancelAll(!alertCancelAll)
    }

    const deleteAllSubs = async () => {
        try {
            const data = await cancelAllAPI()
            dispatch(setCancelAll())
            setAlertCancelAll(false)
            dispatch({ type: 'RESET_STATE' });
        } catch (e) {
            // dispatch(setActive({
            //     title: 'Failed',
            //     active: true,
            //     typeAlet: 'warning',
            //     message: 'subscription already deleted'
            // }))
            enqueueSnackbar('subscription already deleted', { variant: 'error' });
        }
    }

    const handleDeleteByID = (id: any) => {
        setAlertSub(true);
        setIdToDelete(id);
    }

    const deleteSubsById = async () => {
        dispatch(setCancelSubsById(subs.filter((sub: any) => sub.id !== idToDelete)))
        const data = await cancelSubById(idToDelete);
        setAlertSub(false)
        dispatch({ type: 'RESET_STATE' });
        enqueueSnackbar('subscription successfully deleted', { variant: 'success' });
        window.location.reload();
    }

    const { t, i18n } = useTranslation();

    useEffect(() => {
        getRates(i18n.language)
            .then(({ data }: any) => {
                setPlans(divideByPlans(data.payload));
            })
    }, [i18n.language])

    const divideByPlans = (data: any): any => {
        let trialPlan: any = [];
        let basicPlan: any = [];
        let premiumPlan: any = [];

        for (let i = 0; i < data.length; i++) {
            switch (data[i].subscriptionType) {
                case 'TRIAL':
                    trialPlan = [...trialPlan, data[i]];
                    break;
                case 'BASIC':
                    basicPlan = [...basicPlan, data[i]];
                    break;
                case 'PREMIUM':
                    premiumPlan = [...premiumPlan, data[i]];
                    break;
            }
        }
        return {
            trialPlan,
            basicPlan,
            premiumPlan
        }
    }
    const toPayment = async (id: number | string) => {
        let findCurrentBtn = (btn1: any, btn2: any, btn3: any) => {
            if (btn1.current) {
                return btn1
            }
            if (btn2.current) {
                return btn2
            }
            if (btn3.current) {
                return btn3
            }
        }
        let btn = findCurrentBtn(btnTrial, btnBasic, btnPremium)
        try {
            if (btn.current && btn.current.disabled !== true) {
                //@ts-ignore
                btn.current.setAttribute("disabled", "disabled");
                const intervalFetch = (id: string | number) => {
                    dispatch(setStatus('loading'));
                    let paymentInterval = setInterval(async () => {
                        const paymentStatus: any = await getPaymentStatusByID(id)
                        switch (paymentStatus.payload.invoiceStatus) {
                            case "PROCESSING":
                                dispatch(setStatus('loading'));
                                break;
                            case "COMPLETED":
                                dispatch(setStatus('success'));
                                if (document.visibilityState === 'visible') {
                                    setTimeout(() => {
                                        dispatch(setStatus(''));
                                        dispatch(setRole(''))
                                        clearInterval(paymentInterval)
                                    }, 2000)
                                    //@ts-ignore
                                    if (btn.current && btn.current.disabled === true) {
                                        btn.current.removeAttribute("disabled");
                                    }
                                }
                                break;
                            case "REJECTED":
                                dispatch(setStatus('error'));
                                if (document.visibilityState === 'visible') {
                                    setTimeout(() => {
                                        dispatch(setStatus(''));
                                        clearInterval(paymentInterval)
                                    }, 1300)
                                    //@ts-ignore
                                    if (btn.current && btn.current.disabled === true) {
                                        btn.current.removeAttribute("disabled");
                                    }
                                }
                                break;
                            default:
                                dispatch(setStatus('loading'));
                        }
                    }, 5000)
                }
                await paymentById(id)
                    .then((data) => {
                        intervalFetch(data.payload.invoiceId);
                        let iOSiPadOS = navigator.platform.startsWith("iP") ||
                        navigator.platform.startsWith("Mac") && navigator.maxTouchPoints > 4;
                        if (iOSiPadOS) {
                                window.location.assign(data.payload.paymentLink)
                        }
                        else {
                            window.open(data.payload.paymentLink, '_blank')
                        }
                    }
                    )
            }
        } catch (e: any) {
            if (e.response.data.status === 500) {
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            } else {
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            }
        }
    }
    const trialSub = async (id: number | string) => {
        try {
            const data = await paymentById(id)
            enqueueSnackbar('Trial subscription activated', { variant: 'success' });
            dispatch(getRole());
            window.location.reload();
        } catch (e: any) {
            if (e.response.data.status === 500) {
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            } else {
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            }
        }
    }

    if (plans.length == 0) {
        return <>Loading...</>
    }


    return (
        <>
            <div className="subscription">
                <Navigation />
                <h2 className="subscription__title">Select your subscription plan</h2>
                <div className="grid">
                    <Rate plans={plans.trialPlan}
                        listTitle={t('rates.listTitle')}
                        option1={t('rates.premiumRate.option1')}
                        option2={t('rates.premiumRate.option2')}
                        option3={t('rates.premiumRate.option3')}
                        subscriptionType="TRIAL"
                        priceText={t('rates.price')}
                        oldPriceText={t('rates.oldPrice')}
                        btnText={t('rates.btnText')}
                        btnFunc={trialSub}
                        btnRef={btnTrial} />
                    <Rate plans={plans.basicPlan}
                        listTitle={t('rates.listTitle')}
                        option1={t('rates.premiumRate.option1')}
                        option2={t('rates.premiumRate.option2')}
                        option3={t('rates.premiumRate.option3')}
                        subscriptionType="BASIC"
                        priceText={t('rates.price')}
                        oldPriceText={t('rates.oldPrice')}
                        btnFunc={toPayment}
                        btnRef={btnBasic} />
                    <Rate plans={plans.premiumPlan}
                        listTitle={t('rates.listTitle')}
                        option1={t('rates.premiumRate.option1')}
                        option2={t('rates.premiumRate.option2')}
                        option3={t('rates.premiumRate.option3')}
                        subscriptionType="PREMIUM"
                        priceText={t('rates.price')}
                        oldPriceText={t('rates.oldPrice')}
                        btnFunc={toPayment}
                        btnRef={btnPremium} />
                </div>
            </div>
            <ActiveSub handleDeleteByID={handleDeleteByID} openAlertCancelAll={openAlertCancelAll} />
            {alertSub &&
                <Alert>
                    <img onClick={() => setAlertSub(false)} src={CloseIcon} alt="close alert" className="confirm__img" />
                    <h2 className="alert__title">Cancel subscription?</h2>
                    <p className="alert__descr">Are you sure you want to cancel this subscription package?
                        It will not be possible to undo this action. Current subscription will be deactivated</p>
                    <button className="btn-reset alert__btn" onClick={() => deleteSubsById()}>Cancel</button>
                </Alert>}
            {alertCancelAll &&
                <Alert>
                    <img onClick={() => setAlertCancelAll(false)} src={CloseIcon} alt="close alert" className="confirm__img" />
                    <h2 className="alert__title">Cancel all subscriptions?</h2>
                    <p className="alert__descr">Are you sure you want to cancel ALL subscriptions?
                        It will NOT be possible to undo this action. ALL subscriptions will be deactivated</p>
                    <button className="btn-reset alert__btn" onClick={() => deleteAllSubs()}>Cancel</button>
                </Alert>}
        </>
    )
}

export default Subscription;