import React, { useRef, useState } from "react";

import './CustomInput.scss';
import { setError } from "../../redux/slices/registrationSlice";
import { useSelector } from "react-redux";

import { ReactComponent as ViewPass } from './../../assets/svg/viewPass.svg';

interface IInput {
    inputTitle: string;
    inputValue: string,
    setInputValue: (e: any) => any,
    inputType?: string,
    error: any,
    dispatch: (setInputValue: any) => any;
    passwordsCompare?: any;
    placeholder?: string;
    autoComplete?: string;
    name?: string
}

const CustomInput: React.FC<IInput> = ({ inputTitle, inputValue, setInputValue, inputType = 'login', error, dispatch, passwordsCompare, placeholder, autoComplete, name }) => {

    const inputWrapper: any = useRef();
    const inputError: any = useRef();
    const secondPass: any = useRef();

    const [prevState, setPrevState] = useState(inputValue);
    const [prevError, setPrevError] = useState(error);
    const [visiblePass, setVisiblePass] = useState(false);


    const allErrors = useSelector((state: any) => state.registration.error)


    const removeError = () => {
        setPrevState(inputValue)
        setPrevError(error)
        if (inputWrapper.current.classList.contains('input-wrapper--danger')) {
            inputWrapper?.current.classList.remove('input-wrapper--danger');
            if (error) {
                dispatch(setError({ ...allErrors, [(error.field).toLowerCase()]: null }))
            }
            if (secondPass.current) {
                secondPass?.current.classList.add('custom-input__error--hidden');
            }
        }
    }

    const returnError = () => {
        if ((prevState === inputValue) && (prevError || passwordsCompare)) {
            inputWrapper?.current.classList.add('input-wrapper--danger');
            if (prevError) {
                dispatch(setError({ ...allErrors, [(prevError.field).toLowerCase()]: prevError }))
            }
            if (secondPass.current) {
                secondPass?.current.classList.remove('custom-input__error--hidden');
            }
        }
    }

    return (
        <div className="custom-input">
            <label onClick={(e) => e.preventDefault()}>
                <p className="custom-input__title">{inputTitle}</p>
                <div className={error || passwordsCompare ? `input-wrapper input-wrapper--${inputType} input-wrapper--danger` : `input-wrapper input-wrapper--${inputType}`} ref={inputWrapper}>
                    <input
                        name={name}
                        onFocus={() => removeError()}
                        onBlur={() => returnError()}
                        placeholder={placeholder ? placeholder : ''}
                        value={inputValue}
                        autoComplete={autoComplete}
                        onChange={(e) => dispatch(setInputValue(e.target.value))}
                        className="custom-input__input"
                        type={(inputType == 'password') ? ((inputType === 'password' && visiblePass === false) ? 'password' : 'text') : inputType}
                    />
                    {inputType === 'password' &&
                        <span
                            className={visiblePass ? "view-pass view-pass--active" : "view-pass"}
                            onClick={() => setVisiblePass(!visiblePass)}>
                            <ViewPass />
                        </span>}
                </div>
            </label>
            {error && <span ref={inputError} className="custom-input__error">{error.error}</span>}
            {passwordsCompare && inputTitle !== 'Confirm your password:' && <span ref={secondPass} className="custom-input__error">{passwordsCompare.error}</span>}
        </div>
    )
}

export default CustomInput;