import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { unwrapResult } from '@reduxjs/toolkit';

import { setEmail, setPassword, setOtpCode, fetchLogin, setLoginError, fetchPrecheck } from "../../redux/slices/loginSlice";
import { setMfaCode, setEmail as setRegEmail } from "../../redux/slices/registrationSlice";
import { validate } from "../../utils/validate";
import { setActive } from "../../redux/slices/snackbarSlice";
import { fetchRegistration, setPreToken, setStatus, setmfaUrl } from "../../redux/slices/registrationSlice";
import { useNavigate } from "react-router";
import CustomInput from "../customInput/CustomInput";

import CloseIcon from "../closeIcon/CloseIcon";

import { enqueueSnackbar } from 'notistack';


interface ILoginForm {
    dispatch: (func: any) => any;
    toggleForm: () => any;
    setModal: any;
    setCodeModal: any;
}

const LoginForm: React.FC<ILoginForm> = ({ dispatch, toggleForm, setModal, setCodeModal }) => {

    const { error, email, password, otpCode } = useSelector((state: any) => state.login)

    const navigate = useNavigate()

    const handleLogin = (e: any) => {
        e.preventDefault();
        dispatch(setStatus('loading'))
        dispatch(fetchPrecheck({ email, password }))
            .then(unwrapResult)
            .then((data: any) => {
                dispatch(setStatus(''))
                if (data.status === 202) {
                    dispatch(setmfaUrl(data.payload));
                    dispatch(setPreToken(data.payload));
                    dispatch(setRegEmail(email));
                    dispatch(setMfaCode(data.payload))
                    // navigate('/verify')
                } else {
                    setCodeModal(e)
                    enqueueSnackbar('Login success!', { variant: 'success' });
                }
            })
            .catch((rejectedValueOrSerializedError: any) => {
                if (rejectedValueOrSerializedError.status === 401) {
                    enqueueSnackbar(rejectedValueOrSerializedError.message ? rejectedValueOrSerializedError.message : 'Login failed', { variant: 'error' });
                    dispatch(setStatus(''))
                }
                else if (rejectedValueOrSerializedError.status === 409) {
                    enqueueSnackbar(rejectedValueOrSerializedError.message ? rejectedValueOrSerializedError.message : 'Login failed', { variant: 'error' });
                    dispatch(setStatus(''))
                }
                else if (rejectedValueOrSerializedError.status) {
                    for (let i = 0; i < rejectedValueOrSerializedError.payload.length; i++) {
                        enqueueSnackbar(rejectedValueOrSerializedError.payload[i].error ? rejectedValueOrSerializedError.payload[i].error
                            : 'Unable to login', { variant: 'error' });
                    }
                    dispatch(setLoginError(validate(rejectedValueOrSerializedError.payload)))
                    dispatch(setStatus(''))
                }
            })
    }

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleLogin(e)
        }
    };

    return (
        <form onSubmit={handleLogin} onKeyDown={(e) => checkKeyDown(e)}>
            <CloseIcon functionClose={() => navigate('/')} />
            <h1 className="auth__title">Sign in</h1>
            <div className="auth-form">
                <CustomInput
                    inputTitle="Email:"
                    inputValue={email}
                    setInputValue={setEmail}
                    inputType="email"
                    error={error?.email}
                    dispatch={dispatch}
                    name='email'
                    autoComplete='email'/>
                <CustomInput
                    inputTitle="Password:"
                    inputValue={password}
                    setInputValue={setPassword}
                    inputType="password"
                    error={error?.password}
                    dispatch={dispatch}
                    name='password'
                    autoComplete='current-password'/>
                <label>
                    <p onClick={() => setModal(true)} className="auth-form__link">Forgot your password?</p>
                </label>
            </div>
            <div className="btn-group">
                <button className="btn-reset auth__btn auth__btn--filled" type="submit">Sign In</button>
                <button onClick={() => dispatch(toggleForm())} className="btn-reset auth__btn">Create Account</button>
            </div >
        </form>
    )
}

export default LoginForm;