import React from "react";
import { ReactComponent as Close } from './../../assets/svg/dashboard/close--filled.svg';

import './CloseIcon.scss';

interface ICloseIcon {
    functionClose: () => void;
    type?: string;
}

const CloseIcon: React.FC<ICloseIcon> = ({ functionClose, type }) => {
    return <Close onClick={functionClose} className={type === 'danger' ? 'close-icon close-icon--danger' : 'close-icon'} />
}

export default CloseIcon;