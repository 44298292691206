import React, { useEffect } from "react";

import './Snackbar.scss';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearSnackbar } from "../../redux/slices/snackbarSlice";



const Snackbar = () => {

    // const { toggleSnackbar } = useSelector((state: any) => state.registration)

    const { title, message, active, typeAlet } = useSelector((state: any) => state.snackbar);
    const dispatch = useDispatch()

    useEffect(() => {
        const timeOut = setTimeout(() => {
            dispatch(clearSnackbar())
        }, 4000)
        return () => {
            clearInterval(timeOut)
        }
    }, [active])

    return (
        active && (
            <div className={typeAlet === 'warning' ? "snackbar snackbar--danger" : "snackbar"}>
                <h2 className="snackbar__title">{title}</h2>
                <article className="snackbar__article">{message ? message : ''}</article>
            </div>
        )
    )
}

export default Snackbar;