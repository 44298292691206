import React, { useEffect, useRef, useState } from "react";

import './ChooseSub.scss'
import Rate from "../../components/rate/Rate";
import { useTranslation } from "react-i18next";
import { getRates } from "../../api/public/rates";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getRole, setToBuy } from "../../redux/slices/loginSlice";
import { setStatus } from "../../redux/slices/registrationSlice";
import { getPaymentStatusByID } from "../../api/private/paymentStatusAPI";
import { paymentById } from "../../api/private/paymentAPI";
import { setActive } from "../../redux/slices/snackbarSlice";
import Snackbar from "../../components/snackbar/Snackbar";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { useSelector } from "react-redux";

import { enqueueSnackbar } from 'notistack';

const ChooseSubs = () => {
    const { t, i18n } = useTranslation();
    const [plans, setPlans] = useState<any>([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const btnTrial = useRef()
    const btnBasic = useRef()
    const btnPremium = useRef()
    const { status } = useSelector((state: any) => state.registration)

    const divideByPlans = (data: any): any => {
        let trialPlan: any = [];
        let basicPlan: any = [];
        let premiumPlan: any = [];

        for (let i = 0; i < data.length; i++) {
            switch (data[i].subscriptionType) {
                case 'TRIAL':
                    trialPlan = [...trialPlan, data[i]];
                    break;
                case 'BASIC':
                    basicPlan = [...basicPlan, data[i]];
                    break;
                case 'PREMIUM':
                    premiumPlan = [...premiumPlan, data[i]];
                    break;
            }
        }
        return {
            trialPlan,
            basicPlan,
            premiumPlan
        }
    }

    useEffect(() => {
        getRates(i18n.language)
            .then(({ data }: any) => {
                setPlans(divideByPlans(data.payload));
            })
    }, [i18n.language])

    const handleNavigate = () => {
        dispatch(setToBuy(false));
        navigate('/dashboard')
    }

    const toPayment = async (id: number | string) => {
        let findCurrentBtn = (btn1: any, btn2: any, btn3: any) => {
            if (btn1.current) {
                return btn1
            }
            if (btn2.current) {
                return btn2
            }
            if (btn3.current) {
                return btn3
            }
        }
        let btn = findCurrentBtn(btnTrial, btnBasic, btnPremium)
        try {
            if (btn.current && btn.current.disabled !== true) {
                //@ts-ignore
                btn.current.setAttribute("disabled", "disabled");
                const data = await paymentById(id)
                const intervalFetch = (id: string | number) => {
                    dispatch(setStatus('loading'));
                    let paymentInterval = setInterval(async () => {
                        const paymentStatus: any = await getPaymentStatusByID(id)
                        switch (paymentStatus.payload.invoiceStatus) {
                            case "PROCESSING":
                                dispatch(setStatus('loading'));
                                break;
                            case "COMPLETED":
                                dispatch(setStatus('success'));
                                if (document.visibilityState === 'visible') {
                                    setTimeout(() => {
                                        dispatch(setStatus(''));
                                        clearInterval(paymentInterval)
                                    }, 2000)
                                    //@ts-ignore
                                    if (btn.current && btn.current.disabled === true) {
                                        btn.current.removeAttribute("disabled");
                                    }
                                    setTimeout(() => {
                                        dispatch(setStatus(''));
                                        navigate('/dashboard')
                                    }, 2000)
                                }
                                break;
                            case "REJECTED":
                                dispatch(setStatus('error'));
                                if (document.visibilityState === 'visible') {
                                    setTimeout(() => {
                                        dispatch(setStatus(''));
                                        clearInterval(paymentInterval)
                                    }, 1300)
                                    //@ts-ignore
                                    if (btn.current && btn.current.disabled === true) {
                                        btn.current.removeAttribute("disabled");
                                    }
                                }
                                break;
                            default:
                                dispatch(setStatus('loading'));
                        }

                    }, 5000)
                }
                setTimeout(() => {
                    window.open(data.payload.paymentLink, "_blank")
                })
                intervalFetch(data.payload.invoiceId)
            }
        } catch (e: any) {
            if (e.response.data.status === 500) {
                // dispatch(setActive({
                //     title: 'Server error',
                //     active: true,
                //     typeAlet: 'warning',
                //     message: e.response.data.message
                // }))
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            } else {
                // dispatch(setActive({
                //     title: 'Error',
                //     active: true,
                //     typeAlet: 'warning',
                //     message: e.response.data.message
                // }))
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            }
        }
    }

    const trialSub = async (id: number | string) => {
        try {
            const data = await paymentById(id)
            // dispatch(setActive({
            //     title: 'Success',
            //     active: true,
            //     typeAlet: 'success',
            //     message: 'Trial subscription activated'
            // }))
            enqueueSnackbar('Trial subscription activated', { variant: 'success' });
            navigate('/dashboard')
            dispatch(getRole());
        } catch (e: any) {
            if (e.response.data.status === 500) {
                // dispatch(setActive({
                //     title: 'Server error',
                //     active: true,
                //     typeAlet: 'warning',
                //     message: e.response.data.message
                // }))
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            } else {
                // dispatch(setActive({
                //     title: 'Error',
                //     active: true,
                //     typeAlet: 'warning',
                //     message: e.response.data.message
                // }))
                enqueueSnackbar(e.response.data.message ? e.response.data.message : 'Server error', { variant: 'error' });
            }
        }
    }

    if (plans.length == 0) {
        return <>Loading...</>
    }

    return (
        <div className="choose-sub-container">
            <div className="choose-sub">
                <h1 className="choose-sub__title">Choose your subscription</h1>
                <span onClick={() => handleNavigate()} className="choose-sub__skip">SKIP FOR NOW</span>
                <div className="choose-sub__buy">
                    <div className="grid">
                        <Rate plans={plans.trialPlan}
                            listTitle={t('rates.listTitle')}
                            option1={t('rates.premiumRate.option1')}
                            option2={t('rates.premiumRate.option2')}
                            option3={t('rates.premiumRate.option3')}
                            subscriptionType="TRIAL"
                            priceText={t('rates.price')}
                            oldPriceText={t('rates.oldPrice')}
                            btnText={t('rates.btnText')}
                            btnFunc={trialSub}
                            btnRef={btnTrial} />
                        <Rate plans={plans.basicPlan}
                            listTitle={t('rates.listTitle')}
                            option1={t('rates.premiumRate.option1')}
                            option2={t('rates.premiumRate.option2')}
                            option3={t('rates.premiumRate.option3')}
                            subscriptionType="BASIC"
                            priceText={t('rates.price')}
                            oldPriceText={t('rates.oldPrice')}
                            btnFunc={toPayment}
                            btnRef={btnBasic} />
                        <Rate plans={plans.premiumPlan}
                            listTitle={t('rates.listTitle')}
                            option1={t('rates.premiumRate.option1')}
                            option2={t('rates.premiumRate.option2')}
                            option3={t('rates.premiumRate.option3')}
                            subscriptionType="PREMIUM"
                            priceText={t('rates.price')}
                            oldPriceText={t('rates.oldPrice')}
                            btnFunc={toPayment}
                            btnRef={btnPremium} />
                    </div>
                </div>
            </div>
            {status === 'loading' || status === 'error' || status === 'success' ? <LoadingSpinner /> : null}
            <Snackbar />
        </div>
    )
}

export default ChooseSubs;