import { $authHost } from "../index";

// export const getMessages = async (page: number) => {
//     const { data } = await $authHost.post(`/api/dashboard/get/messages?page=${page}&size=10`)
//     return data;
// }

export const getMessages = async (page?: number) => {
    const { data } = await $authHost.post(`/api/dashboard/get/messages?page=${0}&size=1000`)
    return data;
}