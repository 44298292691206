import {$host} from "../index";

export const getRates = async (lang: string) => {
    const cashedDataEN = localStorage.getItem('ratesEN')

    const cacheTimestamp = localStorage.getItem('ratesCacheTimestamp');
    const TEN_MINUTES = 10 * 60 * 1000;
    const isCacheExpired = Date.now() - Number(cacheTimestamp) > TEN_MINUTES;

    if (cashedDataEN && lang === 'en' && !isCacheExpired) {
        return JSON.parse(cashedDataEN)
    }

    const {data} = await $host.get(`/api/general/info/subscription-plans`, {
        headers: {
            Language: lang
        }
    })

    if (lang === 'en') {
        localStorage.setItem('ratesEN', JSON.stringify({data}))
        localStorage.setItem('ratesCacheTimestamp', String(Date.now()));
    }
    return {data}
}