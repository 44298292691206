import axios from "axios";
//@ts-ignore
import Cookies from 'js-cookie';

const baseURL: string = '';

const $host = axios.create({
    baseURL: baseURL
});
const $authHost = axios.create({
    baseURL: baseURL
    // headers: {
    //     Language: localStorage.getItem('lang') || 'en'
    // }
});

const authInterceptor = (config: any) => {
    config.headers.authorization = `Bearer ${Cookies.get('token')}`
    return config
}


axios.defaults.headers.common['Language'] = localStorage.getItem('lang') || 'en'
$authHost.interceptors.request.use(authInterceptor);
$authHost.interceptors.response.use((response): any => {
    return response;
    //add access token logic https://stackoverflow.com/questions/53578718/catching-axios-request-errors-application-wide
    //https://stackoverflow.com/questions/49967779/axios-handling-errors
}, (error: any) => {
    if (error.response.status === 403) {
        Cookies.remove('token');
        window.location.reload();
        localStorage.setItem('error', 'token-rotted')
    }
    return Promise.reject(error);
})



export { $host, $authHost }