import { $host } from "../index";

export const precheckAPI = async (email: string, password: string) => {
    const { data } = await $host.post('/api/auth/jwt/precheck', { email, password }, 
        {
            headers: {
                Language: 'ru'
            }
        })
    return data;
}