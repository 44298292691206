import React from "react";

import './Features.scss'
import { useNavigate } from "react-router";

const Features: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {

    const features = t('features.list', { returnObjects: true });

    const navigate = useNavigate();

    return (
        <section ref={refProp} className="features">
            <div className="container">
                <h2 className="features__title">{t('features.title')}</h2>
                <h3 className="features__subtitle">{t('features.subtitle')}</h3>
                <p className="features__descr">{t('features.descr')}</p>
                <ul className="list-reset features__list">
                    {features.map((item: any, index: any) => (
                        <li key={index} className="feature__item feature">
                            <article className="feature__article">{item.article}</article>
                            <p className="feature__descr">{item.descr}</p>
                        </li>
                    ))}
                </ul>
                <button onClick={() => navigate('/login')} className="btn-reset btn features__btn">{t("features.button")}</button>
            </div>
        </section>
    )
}

export default Features;