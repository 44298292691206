import React, { useState, useEffect, useRef } from "react";

import './Rates.scss'
import { getRates } from "../../api/public/rates";
import Rate from "../rate/Rate";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getRole, setToBuy } from "../../redux/slices/loginSlice";
import { useNavigate } from "react-router";
//@ts-ignore
import Cookies from 'js-cookie';

const Rates: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {

    // const [trialPlan, setTrialPlan] = useState([]);
    // const [basicPlans, setBasicPlans] = useState([]);
    // const [premiumPlans, setPremiumPlans] = useState([]);

    const [plans, setPlans] = useState<any>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const { i18n } = useTranslation();


    useEffect(() => {
        getRates(i18n.language)
            .then(({ data }: any) => {
                setPlans(divideByPlans(data.payload))
            })
    }, [i18n.language])

    const divideByPlans = (data: any): any => {
        let trialPlan: any = [];
        let basicPlan: any = [];
        let premiumPlan: any = [];

        for (let i = 0; i < data.length; i++) {
            switch (data[i].subscriptionType) {
                case 'TRIAL':
                    trialPlan = [...trialPlan, data[i]];
                    break;
                case 'BASIC':
                    basicPlan = [...basicPlan, data[i]];
                    break;
                case 'PREMIUM':
                    premiumPlan = [...premiumPlan, data[i]];
                    break;
            }
        }
        return {
            trialPlan,
            basicPlan,
            premiumPlan
        }
    }


    const handleNavigate = () => {
        dispatch(setToBuy(true));
        if (!Cookies.get('token')) {
            navigate('/login');
        } else {
            navigate('/dashboard/subscriptions')
        }
    }



    if (plans.length == 0) {
        return <section className="rates">Loading...</section>
    }


    return (
        <section ref={refProp} className="rates">
            <h2 className="rates__title">{t('rates.title')}</h2>
            <h3 className="rates__subtitle">{t('rates.subtitle')}</h3>
            <div className="container grid">
                <Rate plans={plans.trialPlan}
                    listTitle={t('rates.listTitle')}
                    option1={t('rates.premiumRate.option1')}
                    option2={t('rates.premiumRate.option2')}
                    option3={t('rates.premiumRate.option3')}
                    subscriptionType="TRIAL"
                    priceText={t('rates.price')}
                    oldPriceText={t('rates.oldPrice')}
                    btnText={t('rates.btnText')}
                    btnFunc={handleNavigate} />
                <Rate plans={plans.basicPlan}
                    listTitle={t('rates.listTitle')}
                    option1={t('rates.premiumRate.option1')}
                    option2={t('rates.premiumRate.option2')}
                    option3={t('rates.premiumRate.option3')}
                    subscriptionType="BASIC"
                    priceText={t('rates.price')}
                    oldPriceText={t('rates.oldPrice')}
                    btnFunc={handleNavigate} />
                <Rate plans={plans.premiumPlan}
                    listTitle={t('rates.listTitle')}
                    option1={t('rates.premiumRate.option1')}
                    option2={t('rates.premiumRate.option2')}
                    option3={t('rates.premiumRate.option3')}
                    subscriptionType="PREMIUM"
                    priceText={t('rates.price')}
                    oldPriceText={t('rates.oldPrice')}
                    btnFunc={handleNavigate} />
            </div>
        </section>
    )
}

export default Rates;