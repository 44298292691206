import React, { useEffect, useRef, useState } from 'react';

import './TableRow.scss';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

const TableRow = ({
  item,
  getLocaleDateTimeString,
  role,
  pinndedArr,
  handlePin,
  id,
  toSpend,
  activeRow,
  setActiveRow,
}) => {
  const [animate, setAnimate] = useState(true);
  const [pinned, setPinned] = useState(false);

  const [active, setActive] = useState(false);
  const [close, setClose] = useState(true);

  const [toSpendState, setToSpendState] = useState(0);

  const ref = useRef();

  // useEffect(() => {
  //     setTimeout(() => {
  //         setAnimate(false)
  //     }, 2000)
  // }, [])

  useEffect(() => {
    if (activeRow === id) {
      document.body.style.overflow = 'hidden';
    }
    return () => (document.body.style.overflow = 'unset');
  }, [activeRow]);

  useEffect(() => {
    if (toSpendState === 0) {
      setToSpendState(toSpend);
    }
  }, [toSpend]);

  const handleAppearInfo = () => {
    setActiveRow(id);
    if (activeRow === id) {
      setActiveRow(null);
    }
  };

  const handleModalActive = (e) => {
    e.stopPropagation();
    setActiveRow(id);
    if (activeRow === id) {
      setActiveRow(null);
    }
  };

  const convertSeconds = (sec) => {
    const minutes = Math.floor(sec / 60);
    const seconds = sec - minutes * 60;
    const hours = Math.floor(minutes / 60);
    const remainder = minutes - hours * 60;
    let days = Math.floor(hours / 24);
    let daysRemainder = hours - days * 24;
    if (days >= 1 && daysRemainder > 0) {
      return `${days}d ${daysRemainder}h`;
    }
    if (days >= 1 && daysRemainder === 0) {
      return `${days}d`;
    }
    if (hours >= 1) {
      return `${hours}h ${remainder > 0 ? `${remainder}m` : ''}`;
    }
    if (minutes > 0 && sec > 0) {
      return `${minutes}m ${seconds > 0 ? `${seconds}s` : ''}`;
    } else if (sec === 0) {
      return `${minutes}m`;
    }
    return `${sec}s`;
  };

  const keys = item.p ? ['0', ...Object.keys(item.p)] : [];

  const computeCaption = (percent, stat) => {
    let caption = ' ';
    const hasPercent = percent && percent !== 0;
    const hasStatic = stat && stat !== 0;
    const delimiter = hasPercent && hasStatic;
    if (hasPercent) {
        caption += (percent * 100).toFixed(5) + '%' + (delimiter ? ' + ' : '');
    }
    if (hasStatic) {
      caption += stat + ' ' + item.s.toUpperCase().replace('USDT', '');
    }
    if (!hasStatic && !hasPercent) {
      caption = ' No commission';
    }
    return caption;
  };

  const commission = (transaction, withdraw) => {
    return withdraw
      ? computeCaption(transaction.cwp, transaction.cws)
      : computeCaption(transaction.cdp, transaction.cds);
  };

  return (
    <>
      <tr
          className={animate ? 'tableRow animate' : 'tableRow'}
          onClick={() => handleAppearInfo()}>
        <th>{item.gr}</th>
        <th>{getLocaleDateTimeString(item.created)}</th>
        <th>{item.s}</th>
        <th>{item.wb}</th>
        <th>{item.ws}</th>
        <th>${toSpendState}</th>
        <th>{Math.floor(item.mp * 100) / 100}%</th>
        <th>{item.wda ? 'Yes' : 'No'}</th>
        <th>{item.v ? 'Yes' : 'No'}</th>
        <th className={item.active ? 'tableRow__active' : 'tableRow__inActive'}>
          <span>{convertSeconds(item.alive)}</span>
        </th>
        <th className="tableRow--hidden">
          <button
              onClick={(e) => handleModalActive(e)}
              className="btn-reset tableRow__btn">
            More details
          </button>
        </th>
        <th style={{width: '30px'}}></th>

        {/*<th*/}
        {/*  className='pin'*/}
        {/*  onClick={(e) => handlePin(e, id, item)}>*/}
        {/*  */}
        {/*</th>*/}
      </tr>
      {activeRow === id && (
          <>
          {role === 'ROLE_PREMIUM_USER' ||
          role === 'ROLE_ADMIN' ? (
            <div
              className={
                close
                  ? 'premium-info premium-info--close premium-info--mobile'
                  : 'premium-info premium-info--mobile'
              }>
              {(item.wb || item.cws) && (
                <tr className="tableRow-premium">
                  <article className="tableRow-premium__article">Commissions:</article>
                  <div className="tableRow-premium__flex">
                    <p className="tableRow-premium__descr">
                      <b>Withdraw ({item.wb}):</b>
                      {commission(item, true)}
                    </p>
                    <p className="tableRow-premium__descr">
                      <b>Deposit ({item.ws}):</b>
                      {commission(item, false)}
                    </p>
                  </div>
                </tr>
              )}
              {(item.wn || item.dn) && (
                <tr className="tableRow-premium">
                  <article className="tableRow-premium__article">
                    Best networks to use:
                  </article>
                  <div className="tableRow-premium__flex">
                    <p className="tableRow-premium__descr">
                      <b>Withdraw ({item.wb}):</b> {item.wn}
                    </p>
                    <p className="tableRow-premium__descr">
                      <b>Deposit ({item.ws}):</b> {item.dn}
                    </p>
                  </div>
                </tr>
              )}
              {keys.length > 0 && (
                <tr className="tableRow-premium">
                  <article className="tableRow-premium__article">
                    Potential trades
                  </article>
                  {keys
                    .slice(0, 10)
                    .map((prof, key) =>
                      item.p[keys[key + 1]] ? (
                        <p className="tableRow-premium__descr">
                          {`$${Math.round(prof)} - ${Math.round(
                            keys[key + 1],
                          )} -- Profit: ${(item.p[keys[key + 1]] * 100).toFixed(2)}%`}
                        </p>
                      ) : null,
                    )}
                </tr>
              )}
              {createPortal(
                <div className="mobile-info">
                  <h3 className="mobile-info__article mobile-info__article--large">
                    Details
                  </h3>
                  <div className="mobile-info__content">
                    <div className="mobile-info__block">
                      <article className="mobile-info__article">Commissions:</article>
                      <p className="mobile-info__descr">
                        <b>Withdraw ({item.wb}):</b>
                        {commission(item, true)}
                      </p>
                      <p className="mobile-info__descr">
                        <b>Deposit ({item.ws}):</b>
                        {commission(item, false)}
                      </p>
                    </div>
                    <div className="mobile-info__block">
                      <article className="mobile-info__article">
                        Best networks to use:
                      </article>
                      <p className="mobile-info__descr">
                        <b>Withdraw ({item.wb}):</b> {item.wn}
                      </p>
                      <p className="mobile-info__descr">
                        <b>Deposit ({item.ws}):</b> {item.dn}
                      </p>
                    </div>

                    {keys.length > 0 && (
                      <div className="mobile-info__block">
                        <article className="mobile-info__article">
                          Potential trades
                        </article>
                        {keys
                          .slice(0, 10)
                          .map((prof, key) =>
                            item.p[keys[key + 1]] ? (
                              <p className="mobile-info__descr">
                                {`$${Math.round(prof)} - ${Math.round(
                                  keys[key + 1],
                                )}  -- Profit: ${(item.p[keys[key + 1]] * 100).toFixed(
                                  2,
                                )}%`}
                              </p>
                            ) : null,
                          )}
                      </div>
                    )}
                    <button
                      onClick={(e) => handleModalActive(e)}
                      className="btn-reset mobile-info__btn">
                      Back
                    </button>
                  </div>
                </div>,
                document.body,
              )}
            </div>
          ) : (
            <div>
              <div
                ref={ref}
                className={
                  close
                    ? 'premium-info no-info premium-info--mobile content-blur'
                    : 'premium-info premium-info--mobile content-blur'
                }>
                <tr>
                  <article className="tableRow-premium__article">Commissions:</article>
                  <div className="tableRow-premium__flex">
                    <p className="tableRow-premium__descr">
                      MEXC. Withdraw: 0.00001 + 1.1%
                    </p>
                    <p className="tableRow-premium__descr">
                      Gateio: Deposit No commissions on deposit
                    </p>
                  </div>
                </tr>
                <tr>
                  <article className="tableRow-premium__article">
                    Best networks to use:
                  </article>
                  <div className="tableRow-premium__flex">
                    <p className="tableRow-premium__descr">Withdraw: BITCOIN</p>
                    <p className="tableRow-premium__descr">Deposit: BTC</p>
                  </div>
                </tr>
                <tr>
                  <article className="tableRow-premium__article">All possi</article>
                  <p className="tableRow-premium__descr">Between: $0 - 93 Profit: 0.1%</p>
                  <p className="tableRow-premium__descr">
                    Between: $93 - 576 Profit: 0.2%
                  </p>
                  <p className="tableRow-premium__descr">
                    Between: $576 - 1576 Profit: 0.1%
                  </p>
                </tr>
                {createPortal(
                  <div className="mobile-info">
                    <div style={{ marginLeft: '-10px' }} className="content-blur">
                      <h3 className="mobile-info__article">Details</h3>
                      <article className="mobile-info__article">Commissions:</article>
                      <p className="mobile-info__descr">MEXC. Withdraw: 0.00001 + 1.1%</p>
                      <p className="mobile-info__descr">
                        Gateio: Deposit No commissions on deposit
                      </p>
                      <article className="mobile-info__article">
                        Best networks to use:
                      </article>
                      <p className="mobile-info__descr">Withdraw: BITCOIN</p>
                      <p className="mobile-info__descr">Deposit: BTC</p>
                      <article className="mobile-info__article">All possi</article>
                      <p className="mobile-info__descr">Between: $0 - 93 Profit: 0.1%</p>
                      <p className="mobile-info__descr">
                        Between: $93 - 576 Profit: 0.2%
                      </p>
                      <p className="mobile-info__descr">
                        Between: $576 - 1576 Profit: 0.1%
                      </p>
                    </div>
                    <button
                      onClick={(e) => handleModalActive(e)}
                      className="btn-reset mobile-info__btn content-blur__btn">
                      Back
                    </button>
                  </div>,
                  document.body,
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TableRow;
