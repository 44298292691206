import { t } from "i18next";
export const comparePass = (password, secondPassword) => {
    let errors = {
        compare: null
    };
    if (password !== secondPassword) {
        errors.compare = {
            field: 'COMPARE',
            error: t('login.errors.passwords')
        }
        return errors;
    }
    return null;
}