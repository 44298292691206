import React, { useEffect, useState } from "react";

import './Connections.scss'
import { getActiveClients } from "../../api/public/activeClients";


const Connections: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {

    const [clients, setClients] = useState<string[]>([])

    useEffect(() => {
        getActiveClients().then(({ data }) => {
            let pairs = data.payload.pairs;

            let cleanedPairs = pairs.map((pair: string) => {
                let sanitized = pair.toUpperCase();
                if (pair.includes('USDT')) {
                    sanitized = pair.toUpperCase().replace('USDT', '');
                }
                return sanitized + ' / USDT';
            });

            setClients(cleanedPairs);
        });
    }, []);

    return (
        <section ref={refProp} className="connections">
            <h2 className="connections__title">{t('connections.title')}</h2>
            <h3 className="connections__subtitle">{t('connections.subtitle')}</h3>
            <p className="connections__descr">{t('connections.descr', { count: clients.length })}</p>
            <ul className="list-reset connections__list">
                {clients.slice(Math.trunc(clients.length / 5)).map((item: string, index) => (
                    <li key={index} className="connections__item">{item}</li>
                ))}
            </ul>
            <ul className="list-reset connections__list connections__list--second">
                {clients.slice(Math.trunc(clients.length / 5), Math.trunc(clients.length / 5) * 2).map((item: string, index) => (
                    <li key={index} className="connections__item">{item}</li>
                ))}
            </ul>
            <ul className="list-reset connections__list">
                {clients.slice(Math.trunc(clients.length / 5) * 2, Math.trunc(clients.length / 5) * 3).map((item: string, index) => (
                    <li key={index} className="connections__item">{item}</li>
                ))}
            </ul>
            <ul className="list-reset connections__list connections__list--second">
                {clients.slice(Math.trunc(clients.length / 5) * 3, Math.trunc(clients.length / 5) * 4).map((item: string, index) => (
                    <li key={index} className="connections__item">{item}</li>
                ))}
            </ul>
            <ul className="list-reset connections__list">
                {clients.slice(Math.trunc(clients.length / 5) * 4, Math.trunc(clients.length / 5) * 5).map((item: string, index) => (
                    <li key={index} className="connections__item">{item}</li>
                ))}
            </ul>
        </section>
    )
}

export default Connections;