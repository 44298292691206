import React from "react";

import './Hero.scss'

import Interface from './../../assets/img/interface.jpg';
import InterfaceNew from './../../assets/img/interface-new.png';
import miniBg from './../../assets/img/mini-bg.jpg'
import { useNavigate } from "react-router";

const Hero = ({ t, tariffs, scrollTo }: any) => {

    const navigate = useNavigate()

    return (
        <section className="hero">
            <div className="container">
                <div className="hero__content">
                    <div className="hero__minibg">
                        <img src={miniBg} alt="mobile interface" />
                    </div>
                    <div className="hero__text">
                        <h1 className="hero__title">{t("hero.title")}</h1>
                        <h1 className="hero__subtitle">{t("hero.subtitle")}</h1>
                        <p className="hero__descr">
                            <p className="hero__descr_paragraph">{t("hero.descr")}</p>
                            <p className="hero__descr_paragraph">{t("hero.descr_2")}</p>
                        </p>
                    </div>
                    <div className="flex btn-group">
                        <button onClick={() => scrollTo(tariffs)} className="btn-reset hero__button hero__button--filled">{t("hero.btn1")}</button>
                        <button onClick={() => navigate('/login')} className="btn-reset hero__button">{t("hero.btn2")}</button>
                    </div>
                    <div className="hero__img-wrapper">
                        <img src={InterfaceNew} alt="interface" className="hero__img" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;