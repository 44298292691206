import React, {useEffect} from "react";

import './Alert.scss';

const Alert = ({ children }: any) => {
    //@ts-ignore
    useEffect(() => {
        const dashboardContainer = document.querySelector('.dashboard-container')
        dashboardContainer!.classList.add('overflow-hidden')
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
            dashboardContainer!.classList.remove('overflow-hidden')
        }
    }, [])

    return (
        <div className="alert">
            <div className="alert__content">
                {children}
            </div>
        </div>
    )
}

export default Alert;