import React, { useEffect, useState } from "react";


import './DashboardLayout.scss';
import Sidebar from "../../components/sidebar/Sidebar";
import { Outlet, useLocation } from "react-router";
import Snackbar from "../../components/snackbar/Snackbar";
import MobileHeader from "../../components/mobileHeader/MobileHeader";
import { useDispatch } from "react-redux";
import { getRole } from "../../redux/slices/loginSlice";
import { useSelector } from "react-redux";
import { TourProvider, useTour } from "@reactour/tour";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

const DashboardLayout = () => {

    const { status } = useSelector((state: any) => state.registration)
    const { t, i18n } = useTranslation<any>('')
    const [filterToggle, setFilterToggle] = useState(false);
    const [settingsToggle, setSettingsToggle] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const dispatch = useDispatch();
    const { role } = useSelector((state: any) => state.login)

    useEffect(() => {
        if (!role) {
            dispatch(getRole())
        }
    }, [role])

    const steps = [
        {
            selector: '.dropdown--1',
            content: t("tooltips.toopltip1"),
        },
        {
            selector: '.dropdown--2',
            content: t("tooltips.toopltip2"),
        },
        {
            selector: '.dropdown--3',
            content: t("tooltips.toopltip3"),
        },
        {
            selector: '.dropdown--datepicker--4',
            content: t("tooltips.toopltip4"),
        },
        {
            selector: '.dropdown--datepicker--5',
            content: t("tooltips.toopltip5"),
        },
        {
            selector: '.filter-profit--1',
            content: t("tooltips.toopltip6"),
        },
        {
            selector: '.filter-profit--2',
            content: t("tooltips.toopltip7"),
        },
        {
            selector: '.exchanges',
            content: t("tooltips.toopltip8"),
        },
        {
            selector: '.profit',
            content: t("tooltips.toopltip9"),
        },
        {
            selector: '.amount',
            content: t("tooltips.toopltip10"),
        },
        {
            selector: '.dashboard-options__item--1',
            content: t("tooltips.toopltip11"),
        },
        {
            selector: '.dashboard-options__item--2',
            content: t("tooltips.toopltip12"),
        },
        {
            selector: '.dashboard-options__item--3',
            content: t("tooltips.toopltip13"),
        },
        {
            selector: '.dashboard-options__item--4',
            content: t("tooltips.toopltip14"),
        },
        {
            selector: '.dashboard-options__item--5',
            content: t("tooltips.toopltip15"),
        },
        {
            selector: '.dashboard-options__item--6',
            content: t("tooltips.toopltip16"),
        },
        {
            selector: '.dashboard-options__item--7',
            content: t("tooltips.toopltip17"),
        },

    ]

    const { setIsOpen, isOpen, currentStep, setCurrentStep } = useTour()

    const endTour = (closeTour: any) => {
        localStorage.setItem('endtour', 'close')
        closeTour()
    }

    // const [currentStep, setCurrentStep] = useState(0)
    return (
        <TourProvider steps={steps}
            styles={{
                popover: (base) => ({
                    ...base,
                    '--reactour-accent': '#3E71EC',
                    fontFamily: 'OpenSans',
                    borderRadius: 10,
                }),
                badge: (base) => ({
                    ...base,
                    borderRadius: 100,
                    fontSize: 18,
                    height: 'auto'
                }),
                maskArea: (base) => ({
                    ...base,
                    color: 'red',
                }),
            }}
            showDots={false}
            disableInteraction={true}
            // currentStep={currentStep}
            // setCurrentStep={() => {
            //     if (currentStep === steps.length - 1) {
            //         setCurrentStep(0)
            //     } else {
            //         setCurrentStep(currentStep + 1)
            //     }
            // }}
            onClickClose={({ setIsOpen }) => {
                endTour(setIsOpen)
            }}
            onClickMask={({ setIsOpen }) => {
                endTour(setIsOpen)
            }}
            prevButton={({ currentStep, setCurrentStep, Button, steps: any }) => {
                const first = currentStep === 0
                return (
                    <>
                        <Button
                            kind="prev"
                            onClick={() => {
                                if (currentStep === 7) {
                                    setMenuActive(false)
                                }
                                if (first) {
                                    setCurrentStep((s) => steps.length - 1)
                                } else {
                                    setCurrentStep((s) => s - 1)
                                }
                            }}
                        >
                        </Button>
                    </>
                )
            }}
            nextButton={({
                Button,
                currentStep,
                stepsLength,
                setIsOpen,
                setCurrentStep,
                steps: any,
            }) => {
                const last = currentStep === stepsLength - 1
                return (
                    <div>
                        <Button
                            onClick={() => {
                                if (currentStep === 6) {
                                    setMenuActive(true)
                                }
                                if (last) {
                                    setIsOpen(false)
                                } else {
                                    setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
                                }
                            }}
                        >
                        </Button>
                    </div>
                )
            }}
        >
            <div className="dashboard-container grid">
                <MobileHeader 
                settingsToggle={settingsToggle} 
                setSettingsToggle={setSettingsToggle} 
                filterToggle={filterToggle} 
                setFilterToggle={setFilterToggle}/>
                {/* {location.pathname === '/dashboard' && <div className="burger-menu-wrapper">
                    <div onClick={() => setMenuActive(!menuActive)} className={menuActive ? 'burger-menu burger-menu--open' : 'burger-menu'}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>} */}
                <Sidebar />
                <Outlet context={[menuActive, setMenuActive, settingsToggle, setSettingsToggle, filterToggle, setFilterToggle]} />
                <Snackbar />
                {status === 'loading' || status === 'error' || status === 'success' ? <LoadingSpinner /> : null}
            </div>
        </TourProvider>
    )
}

export default DashboardLayout;