import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { setActive } from "./snackbarSlice";
import { validate } from "../../utils/validate";
import { $authHost } from "../../api";


type registrationArgs = Record<string, string>;
type veryfiArgs = Record<string, string>;

export const fetchRegistration: any = createAsyncThunk(
    'login/fetchRegistration',
    async (params: registrationArgs, { rejectWithValue }) => {
        const {
            email,
            username,
            password
        } = params;
        try {
            const { data } = await axios.post('/api/auth/jwt/signup', { email, username, password })
            // localStorage.setItem('mfacode', data.payload.mfaCode);
            // localStorage.setItem('mfaurl', data.payload.mfaUrl);
            // localStorage.setItem('pretoken', data.payload.pretoken);
            return data;
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const sendEmailCode: any = createAsyncThunk(
    'login/fetchToEmail',
    async (preToken: string | null | any) => {
        if (preToken) {
            const { data } = await axios.post('/api/auth/jwt/send-email-token/by-pre-token', { preToken: preToken })
            return data
        }
        else {
            const { data } = await $authHost.post('/api/auth/jwt/send-email-token')
            return data
        }
    }
)

export const verifyAccount: any = createAsyncThunk(
    'login/fetchVerify',
    async (params: veryfiArgs, { rejectWithValue }) => {
        const {
            preToken,
            QRcode,
            emailCode
        } = params;
        try {
            const { data } = await $authHost.post('/api/auth/jwt/confirm', { preToken, otpCode: QRcode, emailCode })
            return data;
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)



interface IRegistration {
    isLogin: boolean,
    email: string,
    username: string,
    password: string,
    secondPassword: string;
    preToken: string;
    mfaUrl: string;
    status: '' | 'loading' | 'success' | 'error';
    emailCode: string;
    QRcode: string;
    toggleSnackbar: string;
    error: any;
    mfaCode: string;
}

const initialState: IRegistration = {
    isLogin: false,
    email: '',
    username: '',
    password: '',
    secondPassword: '',
    preToken: '',
    mfaUrl: '',
    status: '', // loading | success | error,
    emailCode: '',
    QRcode: '',
    toggleSnackbar: '',
    error: [],
    mfaCode: ''
}

export const registrationSlice = createSlice({
    name: 'registation',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        toggleForm: (state) => {
            state.isLogin = !state.isLogin
        },
        clearForm: (state) => {
            state.isLogin = false;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setSecondPassword: (state, action) => {
            state.secondPassword = action.payload;
        },
        setPreToken: (state, action) => {
            state.preToken = action.payload.preToken;
        },
        setmfaUrl: (state, action) => {
            state.mfaUrl = action.payload.mfaUrl;
        },
        setEmailCode: (state, action) => {
            state.emailCode = action.payload;
        },
        setQRcode: (state, action) => {
            state.QRcode = action.payload;
        },
        setInvalidPasswords: (state, action) => {
            state.error = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setMfaCode: (state, action) => {
            state.mfaCode = action.payload.mfaCode;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRegistration.pending, (state) => {
            state.preToken = '';
            state.mfaUrl = '';
            state.status = 'loading';
        })
        builder.addCase(fetchRegistration.fulfilled, (state, action) => {
            state.preToken = action.payload.payload.preToken;
            state.mfaUrl = action.payload.payload.mfaUrl;
            state.mfaCode = action.payload.payload.mfaCode;
            state.status = '';
            localStorage.setItem('pretoken', action.payload.payload.preToken);
            localStorage.setItem('mfaUrl', action.payload.payload.mfaUrl);
            localStorage.setItem('mfaCode', action.payload.payload.mfaCode);
        })
        builder.addCase(fetchRegistration.rejected, (state) => {
            state.preToken = '';
            state.mfaUrl = '';
            state.status = '';
        })
        builder.addCase(sendEmailCode.pending, (state) => {
            state.status = 'loading';
            state.toggleSnackbar = 'true'
        })
        builder.addCase(sendEmailCode.fulfilled, (state) => {
            state.toggleSnackbar = 'false'
            state.status = '';
        })
        builder.addCase(sendEmailCode.rejected, (state, action) => {
            state.toggleSnackbar = 'false';
            state.status = '';
        })
    },
})

export const {
    setStatus, toggleForm, setEmail, setUsername, setPassword, setMfaCode, clearForm,
    setSecondPassword, setPreToken, setmfaUrl, setEmailCode, setQRcode, setInvalidPasswords, setError
} = registrationSlice.actions;

export default registrationSlice.reducer