import React, { useState, useEffect, useMemo } from 'react';
import Filter from '../../components/filter/Filter';

import './Notifications.scss';
import Settings from '../../components/settings/Settings';
import Navigation from '../../components/navigation/Navigation';

import { useSelector } from 'react-redux';
import TableRow from '../../components/tableRow/tableRow';
import { useDispatch } from 'react-redux';
import {
  getIdentificators,
  getNotifications,
  setNotifications,
  setPage,
  setPaginationPages,
} from '../../redux/slices/websocketSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useOutletContext } from 'react-router';
import { deleteFilter } from '../../redux/slices/dashboardSlice';

import { ReactComponent as SortArrows } from './../../assets/svg/dashboard/sortarrow.svg';

import deleteFilterIcon from './../../assets/svg/dashboard/close-white.svg';
import { useSortableData } from '../../hooks/useSortableData';

import { TourProvider, useTour } from '@reactour/tour';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getSubs } from '../../redux/slices/subscriptionSlice';
import ReactPaginate from 'react-paginate';

const Notifications = () => {
  const [menuActive, setMenuActive, settingsToggle, setSettingsToggle, filterToggle, setFilterToggle] =
    useOutletContext();
  const { notifications, time, pages, paginationPages, error, identificators } =
    useSelector((state) => state.websocket);
  const { role } = useSelector((state) => state.login);
  const { applyedFilters, filterApplyed, firstLogin } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const profit = useSelector((state) => state.dashboard.settings.usdtToSpend);
  const { subs } = useSelector((state) => state.subscriptions);
  const loadingSettings = useSelector((state) => state.dashboard.loading);

  const [activeRow, setActiveRow] = useState(null);

  const getLocaleDateTimeString = (date) => {
    date = JSON.stringify(date).replace('.', '');
    if (date.length === 12) {
      date = date + '0';
    }
    if (date.length > 12) {
      date = date.substr(0, 13);
    }
    date = new Date(JSON.parse(date));
    return date.toISOString().slice(0, 19).replace('T', ' ');
  };
  const { items, requestSort, sortConfig } = useSortableData(notifications);

  useEffect(() => {
    dispatch(getIdentificators());
  }, [time]);

  // useEffect(() => {
  //   if (role) {
  //     dispatch(getNotifications(pages));
  //     dispatch(setPage(pages));
  //   }
  // }, [role]);

  useEffect(() => {
    if (role) {
      dispatch(getNotifications(paginationPages));
    }
  }, [role, paginationPages]);

  useEffect(() => {
    if (
      items.filter(
        (item) =>
          (applyedFilters['Grades'] ? item.gr === applyedFilters['Grades'] : item) &&
          (applyedFilters['Date From']
            ? new Date(getLocaleDateTimeString(item.created)).getTime() >=
              new Date(applyedFilters['Date From']).getTime()
            : item) &&
          (applyedFilters['Date To']
            ? new Date(getLocaleDateTimeString(item.created)).getTime() <=
              new Date(applyedFilters['Date To']).getTime()
            : item) &&
          (applyedFilters['Buy Exchange']
            ? item.wb === applyedFilters['Buy Exchange']
            : item) &&
          (applyedFilters['Sell Exchange']
            ? item.ws === applyedFilters['Sell Exchange']
            : item) &&
          (applyedFilters['Profit From']
            ? item.mp > applyedFilters['Profit From']
            : item) &&
          (applyedFilters['Profit To'] ? item.mp < applyedFilters['Profit To'] : item) &&
          (applyedFilters['Available']
            ? item.wda === applyedFilters['Available']
            : item) &&
          (applyedFilters['Verified'] ? item.v === applyedFilters['Verified'] : item) &&
          (applyedFilters['Active'] ? item.active === applyedFilters['Active'] : item),
      ).length <= 15
    ) {
      // fetchNextData(pages);
      if (role) {
        fetchNextData(paginationPages);
      }
    }
  }, [role, applyedFilters]);

  useEffect(() => {
    setLoading(true);
    if (subs.length === 0) {
      dispatch(getSubs());
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  // const fetchNextData = () => {
  //   dispatch(setPage(pages));
  //   dispatch(getNotifications(pages));
  // };

  const fetchNextData = () => {
    // dispatch(setPage(pages));
    dispatch(getNotifications(paginationPages));
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  //const [filterToggle, setFilterToggle] = useState(false);

  const { height, width } = useWindowDimensions();
  const { setIsOpen, isOpen, currentStep, setCurrentStep } = useTour();

  useEffect(() => {
    if (firstLogin && loadingSettings === 'false') {
      if (width < 1445 && width > 900) {
        if (currentStep >= 7) {
          setMenuActive(true);
          setCurrentStep(currentStep);
        }
      }
      if (width < 900 || height < 800) {
        setSettingsToggle(true);
        setFilterToggle(true);
      }
      setIsOpen(true);
    }
  }, [currentStep]);

  const [pinndedArr, setPinnedArr] = useState([]);
  const [pinnedItems, setPinnedItems] = useState([]);

  const handlePin = (e, key, item) => {
    e.stopPropagation();
    if (pinndedArr.length === 3 || pinnedItems === 3) {
      let pinnedArrShifted = pinndedArr.splice(0, 1);
      let pinnedItemsShifted = pinnedItems.splice(0, 1);
      setPinnedArr([pinnedArrShifted]);
      setPinnedItems([pinnedItemsShifted]);
    }
    if (!pinndedArr.includes(key)) {
      setPinnedArr([...pinndedArr, key]);
      setPinnedItems([...pinnedItems, item]);
    } else {
      let poped = pinndedArr.filter((val) => val !== key);
      let popedPinned = pinnedItems.filter((val) => val.s + val.wb + val.ws !== key);
      setPinnedArr(poped);
      setPinnedItems(popedPinned);
    }
  };

  const handleTPin = (e, key, item) => {
    // e.stopPropagation();
    // let poped = pinnedItems.filter((val) => val.s + val.wb + val.ws !== key);
    // let popedArr = pinndedArr.filter((val) => val !== key);
    // setPinnedArr(popedArr);
    // setPinnedItems(poped);
  };

  const notificationPerPage = 10;
  const [notificationLength, setNotificationsLength] = useState(0);
  const pageNumbers = [];
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  for (let i = 1; i <= Math.ceil(notificationLength / notificationPerPage); i++) {
    pageNumbers.push(i);
  }

  const notificationsData = useMemo(() => {
    let computedNotifications = items;
    computedNotifications = computedNotifications.filter(
      (item) =>
        (applyedFilters['Grades'] ? item.gr === applyedFilters['Grades'] : item) &&
        (applyedFilters['Date From']
          ? new Date(getLocaleDateTimeString(item.created)).getTime() >=
            new Date(applyedFilters['Date From']).getTime()
          : item) &&
        (applyedFilters['Date To']
          ? new Date(getLocaleDateTimeString(item.created)).getTime() <=
            new Date(applyedFilters['Date To']).getTime()
          : item) &&
        (applyedFilters['Buy Exchange']
          ? item.wb === applyedFilters['Buy Exchange']
          : item) &&
        (applyedFilters['Sell Exchange']
          ? item.ws === applyedFilters['Sell Exchange']
          : item) &&
        (applyedFilters['Profit From']
          ? item.mp > applyedFilters['Profit From']
          : item) &&
        (applyedFilters['Profit To'] ? item.mp < applyedFilters['Profit To'] : item) &&
        (applyedFilters['Available'] ? item.wda === applyedFilters['Available'] : item) &&
        (applyedFilters['Verified'] ? item.v === applyedFilters['Verified'] : item) &&
        (applyedFilters['Active'] ? item.active === applyedFilters['Active'] : item),
    );

    // dispatch(setNotifications(computedNotifications));
    setNotificationsLength(computedNotifications.length);
    //Current Page slice
    return computedNotifications.slice(
      (currentPage - 1) * notificationPerPage,
      (currentPage - 1) * notificationPerPage + notificationPerPage,
    );
  }, [currentPage, items, applyedFilters]);

  const [activeSortingBlock, setActiveSortingBlock] = useState(7);

  const sortingHandler = (key, index) => {
    requestSort(key);
    setActiveSortingBlock(index);
  };

  const noSubscription = () => {
    return role === 'ROLE_BASIC_USER';
  }

  const noNotifications = () => {
    return notificationsData.length === 0 && role !== 'ROLE_BASIC_USER';
  }

  const tooStrictFilters = () => {
    return subs.length !== 0 && !error && notificationsData.length === 0;
  }

  const noConnection = () => {
    return !!(subs.length !== 0 && error);
  }

  return (
    <>
      <div className={menuActive ? 'notification' : 'notification notification--wide'}>
        <Navigation menuActive={menuActive} setMenuActive={setMenuActive} />
        <div className="notification-mobile">
          <div className="notification-mobile-dropdown notification-mobile-settings">
            <div
              onClick={() => setSettingsToggle(!settingsToggle)}
              className={
                settingsToggle
                  ? 'notification-mobile__btn notification-mobile__btn--active'
                  : 'notification-mobile__btn'
              }>
              <article className="notification-mobile-article">
                Notification settings:
              </article>
            </div>
            {settingsToggle && (
              <Settings menuActive={false} setMenuActive={setMenuActive} />
            )}
          </div>
          <div className="notification-mobile-dropdown notification-mobile-filter">
            <div
              onClick={() => setFilterToggle(!filterToggle)}
              className={
                filterToggle
                  ? 'notification-mobile__btn notification-mobile__btn--active'
                  : 'notification-mobile__btn'
              }>
              <article className="notification-mobile-article">Filter results:</article>
            </div>
            {filterToggle && <Filter />}
          </div>
        </div>
        <h2 className="notification__title">Filter results:</h2>
        <Filter />
        <div className="notification__connect">
          <h2 className="notification__title notification__title--wide">Notifications</h2>
          {time && !error ? (
            <p className="notification__descr">
              {width > 890 && `Last update: ${time}`}
              {width < 890 && `Last update: ${time.slice(10, 17)}`}
            </p>
          ) : (
            <></>
          )}
          {error ? (
            <p className="notification__descr notification__descr--failed">
              Connection failed: {time}
            </p>
          ) : (
            <></>
          )}
        </div>
        {applyedFilters && (
          <div className="applyed-filters">
            {Object.keys(applyedFilters).map((result, index) => (
              <div key={index} className="applyed-filters__item">
                {result}
                <img
                  onClick={() => dispatch(deleteFilter(result))}
                  className="applyed-filters__img"
                  src={deleteFilterIcon}
                  alt="delete filter"
                />
              </div>
            ))}
          </div>
        )}
        <div className="notification__table">
          <table className="table">
            <thead className="table__header">
              <tr>
                <th
                  onClick={() => sortingHandler('gr', 0)}
                  className={activeSortingBlock === 0 ? 'sort sort--active' : 'sort'}>
                  Grade
                  <SortArrows />
                </th>
                <th
                  onClick={() => sortingHandler('created', 1)}
                  className={activeSortingBlock === 1 ? 'sort sort--active' : 'sort'}>
                  Date
                  <SortArrows />
                </th>
                <th
                  onClick={() => sortingHandler('s', 2)}
                  className={activeSortingBlock === 2 ? 'sort sort--active' : 'sort'}>
                  Coin pair
                  <SortArrows />
                </th>
                <th
                  onClick={() => sortingHandler('wb', 3)}
                  className={activeSortingBlock === 3 ? 'sort sort--active' : 'sort'}>
                  Buy
                  <SortArrows />
                </th>
                <th
                  onClick={() => sortingHandler('ws', 4)}
                  className={activeSortingBlock === 4 ? 'sort sort--active' : 'sort'}>
                  Sell
                  <SortArrows />
                </th>
                <th
                  onClick={() => sortingHandler('profit', 5)}
                  className={activeSortingBlock === 5 ? 'sort sort--active' : 'sort'}>
                  To spend
                  <SortArrows />
                </th>
                <th
                  onClick={() => sortingHandler('mp', 6)}
                  className={activeSortingBlock === 6 ? 'sort sort--active' : 'sort'}>
                  Profit
                  <SortArrows />
                </th>
                <th>Available</th>
                <th>Verified</th>
                <th
                  onClick={() => sortingHandler('alive;active', 7)}
                  className={activeSortingBlock === 7 ? 'sort sort--active' : 'sort'}>
                  Opened
                  <SortArrows />
                </th>
                <th className="pin"></th>
                <th></th>
              </tr>
            </thead>
            {noSubscription() ? (
                <div className="notifications-empty">
                  <p className="notifications-empty__descr">
                    You currently don’t have an active subscription.
                  </p>
                  <p className="notifications-empty__descr">
                    Please select a subscription to enable notifications.
                  </p>
                </div>
            ) : noNotifications() ? (
                <div className="notifications-empty">
                  <p className="notifications-empty__descr">
                    No notifications at the moment.
                  </p>
                  <p className="notifications-empty__descr">
                    Please wait for the next update (~2 minutes).
                  </p>
                </div>
            ) : tooStrictFilters() ? (
                <div className="notifications-empty">
                  <p className="notifications-empty__descr">
                    We’re receiving updates, but they don’t match your settings
                  </p>
                  <p className="notifications-empty__descr">
                    Please wait a few minutes for the next update, or adjust your settings
                    to cover a wider range.
                  </p>
                </div>
            ) : noConnection() ? (
                <div className="notifications-empty">
                  <p className="notifications-empty__descr">
                    Connection is currently unavailable.
                  </p>
                  <p className="notifications-empty__descr">
                    We're working to fix it. If the issue persists by the end of the day,
                    please contact support
                  </p>
                </div>
            ) : (
                <tbody>
                <tr>
                  <th>
                    {/* <InfiniteScroll
                    dataLength={notifications.length}
                    next={fetchNextData}
                    hasMore={true}
                    scrollableTarget="scrollTable"> */}
                    <table style={{minWidth: '100%'}}>
                      <tbody className="table__body" id="scrollTable">
                      {pinnedItems.map((item, index) => (
                          <TableRow
                              key={item.s + item.wb + item.ws}
                              id={item.s + item.wb + item.ws}
                              item={item}
                              handlePin={handleTPin}
                              pinndedArr={pinndedArr}
                              getLocaleDateTimeString={getLocaleDateTimeString}
                              role={role}
                              toSpend={profit}
                              activeRow={activeRow}
                              setActiveRow={setActiveRow}
                          />
                      ))}
                      {
                        // items
                        //   .filter(
                        //     (item) =>
                        //       (applyedFilters['Grades']
                        //         ? item.gr === applyedFilters['Grades']
                        //         : item) &&
                        //       (applyedFilters['Date From']
                        //         ? new Date(
                        //             getLocaleDateTimeString(item.created),
                        //           ).getTime() >=
                        //           new Date(applyedFilters['Date From']).getTime()
                        //         : item) &&
                        //       (applyedFilters['Date To']
                        //         ? new Date(
                        //             getLocaleDateTimeString(item.created),
                        //           ).getTime() <=
                        //           new Date(applyedFilters['Date To']).getTime()
                        //         : item) &&
                        //       (applyedFilters['Buy Exchange']
                        //         ? item.wb === applyedFilters['Buy Exchange']
                        //         : item) &&
                        //       (applyedFilters['Sell Exchange']
                        //         ? item.ws === applyedFilters['Sell Exchange']
                        //         : item) &&
                        //       (applyedFilters['Profit From']
                        //         ? item.mp > applyedFilters['Profit From']
                        //         : item) &&
                        //       (applyedFilters['Profit To']
                        //         ? item.mp < applyedFilters['Profit To']
                        //         : item) &&
                        //       (applyedFilters['Available']
                        //         ? item.wda === applyedFilters['Available']
                        //         : item) &&
                        //       (applyedFilters['Verified']
                        //         ? item.v === applyedFilters['Verified']
                        //         : item),
                        //   )
                        notificationsData
                            .sort((a, b) => (a.active > b.active ? -1 : 1))
                            .map((item, index) => (
                                <TableRow
                                    key={item.s + item.wb + item.ws}
                                    id={item.s + item.wb + item.ws}
                                    item={item}
                                    handlePin={handlePin}
                                    pinndedArr={pinndedArr}
                                    getLocaleDateTimeString={getLocaleDateTimeString}
                                    role={role}
                                    toSpend={profit}
                                    activeRow={activeRow}
                                    setActiveRow={setActiveRow}
                                />
                            ))
                      }
                      </tbody>
                    </table>
                    {/* </InfiniteScroll> */}
                  </th>
                </tr>
                </tbody>
            )}
          </table>
        </div>
        <ReactPaginate
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            activeClassName={'activepage'}
            breakClassName={'ellipsis'}
            nextClassName="btn-none"
            previousClassName="btn-none"
            pageCount={pageNumbers.length}
            onPageChange={(event) => paginate(event.selected + 1)}
            breakLabel="..."
            previousLabel={null}
            nextLabel={null}
            pageRangeDisplayed={width > 600 ? 6 : 2}
        />
      </div>
      <Settings menuActive={menuActive}/>
    </>
  );
};

export default Notifications;
