export const validate = (values) => {
    let errors = {};
    if (values) {
        for (let i = 0; i < values.length; i++) {
            if (values[i].field === 'EMAIL') {
                errors.email = values[i]
            }
            if (values[i].field === 'PASSWORD') {
                errors.password = values[i]
            }
            if (values[i].field === 'USERNAME') {
                errors.username = values[i]
            }
            // if (values[i].field === 'OTP') {
            //     errors.otpCode = values[i]
            // }
            if (values[i].field === 'EMAIL_CODE') {
                errors.code = values[i]
            }
        }
        return errors;
    }
}