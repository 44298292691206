import React, { useEffect, useState } from "react";

import './Settings.scss';
import { useDispatch } from "react-redux";
import {
    fetchGetSettings, fetchSetSettings, setAvailableExchange, setBestNetwork, setComissionsC, setComissionsR,
    setExchanges, setMinimalProfit, setShowPossibles, setUsdToSpend, setVerifiedByContact, setVerifiedOnly
} from "../../redux/slices/dashboardSlice";
import { useSelector } from "react-redux";
import { setActive } from "../../redux/slices/snackbarSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import Toopltip from "../tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { enqueueSnackbar } from 'notistack';
import { clearNotifications, getNotifications, setPage } from "../../redux/slices/websocketSlice";


const Settings = (menuActive: any) => {


    const dispatch = useDispatch()
    const { t, i18n } = useTranslation<any>('')
    const { settings, loading } = useSelector((state: any) => state.dashboard);
    const exchages = useSelector((state: any) => state.dashboard.settings.exchages)
    const { role } = useSelector((state: any) => state.login);

    // const { height, width } = useWindowDimensions();
    // const { pages } = useSelector((state: any) => state.websocket);
    const [search, setSearch] = useState('')

    const [disabledBtn, setDisabledBtn] = useState(false)

    const ApplySettings = () => {
        setDisabledBtn(true);
        dispatch(fetchSetSettings(settings))
            .unwrap()
            .then(() => {
                // dispatch(setActive({
                //     active: true,
                //     typeAlet: '',
                //     message: 'settings successfully updated'
                // }))
                enqueueSnackbar('Settings successfully updated', { variant: 'success' });
                dispatch(clearNotifications())
                dispatch(getNotifications(0));
            })
        setTimeout(() => {
            setDisabledBtn(false)
        }, 2000)
    }

    useEffect(() => {
        dispatch(fetchGetSettings())
    }, [])

    // useEffect(() => {
    //     let body = document.querySelector(".dashboard-container")!;
    //     if (width < 1450 && menuActive.menuActive) {
    //         //@ts-ignore
    //         body.style.overflow = 'hidden';
    //     }
    //     return () => {
    //         //@ts-ignore
    //         body.style.overflow = 'auto';
    //     }
    // }, [width, menuActive])



    return (
        <div className={menuActive.menuActive ? "dashboard-settings" : "dashboard-settings dashboard-settings--toggle"}>
            <div className="dashboard-settings__content">
                <article className="dashboard-settings__header">Notification  settings:</article>
                <div className="exchanges">
                    <article className="dashboard-settings__article exchanges__article">Exchanges
                        <Toopltip tooltipArticle={t("tooltips.toopltip10")} inSettings={true} />
                    </article>
                    <div className="exchanges__search">
                        <input value={search} onChange={(e) => setSearch(e.target.value.toLocaleUpperCase())} type="text" />
                    </div>
                    <ul className="list-reset exchanges__list">
                        {loading === true ? (
                            <SkeletonTheme>
                                <li className="exchanges__item exchanges__item--skeleton">
                                    <label className="custom-checkbox">
                                        <Skeleton height={24} width={24} />
                                        <span className="custom-checkbox__title"><Skeleton height={24} /></span>
                                    </label>
                                </li>
                                <li className="exchanges__item exchanges__item--skeleton">
                                    <label className="custom-checkbox">
                                        <Skeleton height={24} width={24} />
                                        <span className="custom-checkbox__title"><Skeleton height={24} /></span>
                                    </label>
                                </li>
                                <li className="exchanges__item exchanges__item--skeleton">
                                    <label className="custom-checkbox">
                                        <Skeleton height={24} width={24} />
                                        <span className="custom-checkbox__title"><Skeleton height={24} /></span>
                                    </label>
                                </li>
                                <li className="exchanges__item exchanges__item--skeleton">
                                    <label className="custom-checkbox">
                                        <Skeleton height={24} width={24} />
                                        <span className="custom-checkbox__title"><Skeleton height={24} /></span>
                                    </label>
                                </li>
                                <li className="exchanges__item exchanges__item--skeleton">
                                    <label className="custom-checkbox">
                                        <Skeleton height={24} width={24} />
                                        <span className="custom-checkbox__title"><Skeleton height={24} /></span>
                                    </label>
                                </li>
                            </SkeletonTheme>
                        ) : (
                            <>
                                {Object.keys(exchages)
                                    .filter(searchingElem => search === "" || searchingElem.includes(search))
                                    .map((keyName, index) => (
                                        <li key={index} className="exchanges__item">
                                            <label className="custom-checkbox">
                                                <input checked={exchages[keyName]} onChange={() => dispatch(setExchanges({ ...exchages, [keyName]: !exchages[keyName] }))} type="checkbox" className="custom-checkbox__field" />
                                                <span className="custom-checkbox__title">{keyName}</span>
                                            </label>
                                        </li>
                                    ))}
                            </>)}
                    </ul>
                </div>
                <div className="profit">
                    <article className="dashboard-settings__article profit__article">Target profit
                        <Toopltip tooltipArticle={t("tooltips.toopltip11")} inSettings={true} />
                    </article>
                    <input type="range" min='1' max='50' step={1} className="dashboard-settings__range profit__input" value={settings.minimalProfitPercent} onChange={(e) => dispatch(setMinimalProfit(e.target.value))} />
                    <div className="profit__range">
                        <span>{settings.minimalProfitPercent}%</span>
                        <span>50%</span>
                    </div>
                </div>
                <div className="amount">
                    <article className="dashboard-settings__article amount__article">Trade amount
                        <Toopltip tooltipArticle={t("tooltips.toopltip12")} inSettings={true} />
                    </article>
                    <input type="range" min='100' max='10000' step={10} className="dashboard-settings__range amount_input" value={settings.usdtToSpend} onChange={(e) => dispatch(setUsdToSpend(e.target.value))} />
                    <div className="profit__range">
                        <span>{settings.usdtToSpend} USD</span>
                        <span>10k USD</span>
                    </div>
                </div>
                <div className="dashboard-options">
                    <article className="dashboard-settings__article dashboard-options__article">Options
                    </article>
                    <ul className="list-reset dashboard-options__list">
                        <li className="dashboard-options__item dashboard-options__item--1">
                            <label className="custom-checkbox">
                                <input checked={settings.includeCommissionsC} onChange={() => dispatch(setComissionsC(!settings.includeCommissionsC))} type="checkbox" className="custom-checkbox__field" />
                                <span className="custom-checkbox__title">Include commissions
                                    <Toopltip tooltipArticle={t("tooltips.toopltip13")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                        <li className="dashboard-options__item dashboard-options__item--2">
                            <label className="custom-checkbox">
                                <input checked={settings.includeDepAndWithAvailableOnly} onChange={() => dispatch(setAvailableExchange(!settings.includeDepAndWithAvailableOnly))} type="checkbox" className="custom-checkbox__field" />
                                <span className="custom-checkbox__title">Available
                                    <Toopltip tooltipArticle={t("tooltips.toopltip14")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                        <li className="dashboard-options__item dashboard-options__item--3">
                            <label className="custom-checkbox">
                                <input checked={settings.includeVerifiedOnly} onChange={() => dispatch(setVerifiedOnly(!settings.includeVerifiedOnly))} type="checkbox" className="custom-checkbox__field" />
                                <span className="custom-checkbox__title">Verified
                                    <Toopltip tooltipArticle={t("tooltips.toopltip15")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div className="dashboard-options dashboard-options--premium">
                    <article
                        className={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN' ? "dashboard-settings__article dashboard-options__article" : "dashboard-settings__article dashboard-options__article dashboard-settings__article--disabled"}>
                        Premium options
                    </article>
                    <ul className="list-reset dashboard-options__list">
                        <li className="dashboard-options__item dashboard-options__item--4">
                            <label className="custom-checkbox">
                                <input disabled={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN' ? false : true}
                                    checked={settings.includeBestNetworks}
                                    onChange={() => dispatch(setBestNetwork(!settings.includeBestNetworks))}
                                    type="checkbox" className="custom-checkbox__field" />
                                <span
                                    className={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN'  ? "custom-checkbox__title" : "custom-checkbox__title  custom-checkbox__title--disabled"}>Best networks
                                    <Toopltip tooltipArticle={t("tooltips.toopltip16")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                        <li className="dashboard-options__item dashboard-options__item--5">
                            <label className="custom-checkbox">
                                <input disabled={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN' ? false : true}
                                    checked={settings.includeCommissionsR}
                                    onChange={() => dispatch(setComissionsR(!settings.includeCommissionsR))}
                                    type="checkbox" className="custom-checkbox__field" />
                                <span
                                    className={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN' ? "custom-checkbox__title" : "custom-checkbox__title  custom-checkbox__title--disabled"}>Show fees
                                    <Toopltip tooltipArticle={t("tooltips.toopltip17")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                        <li className="dashboard-options__item dashboard-options__item--6">
                            <label className="custom-checkbox">
                                <input
                                    disabled={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN'? false : true}
                                    checked={settings.includeAllPossibles}
                                    onChange={() => dispatch(setShowPossibles(!settings.includeAllPossibles))}
                                    type="checkbox" className="custom-checkbox__field" />
                                <span
                                    className={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN'? "custom-checkbox__title" : "custom-checkbox__title  custom-checkbox__title--disabled"}>Show potential
                                    <Toopltip tooltipArticle={t("tooltips.toopltip18")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                        <li className="dashboard-options__item dashboard-options__item--7">
                            <label className="custom-checkbox">
                                <input
                                    disabled={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN'  ? false : true}
                                    checked={settings.includeVerifiedByContractOnly}
                                    onChange={() => dispatch(setVerifiedByContact(!settings.includeVerifiedByContractOnly))}
                                    type="checkbox" className="custom-checkbox__field" />
                                <span
                                    className={role === 'ROLE_PREMIUM_USER' || role === 'ROLE_ADMIN' ? "custom-checkbox__title" : "custom-checkbox__title  custom-checkbox__title--disabled"}>Verified by contract
                                    <Toopltip tooltipArticle={t("tooltips.toopltip19")} inSettings={true} />
                                </span>
                            </label>
                        </li>
                    </ul>
                </div>
                <button disabled={disabledBtn} onClick={() => ApplySettings()} className={!disabledBtn ?
                    'btn-reset dashboard-settings__btn dashboard-settings__btn--filled' :
                    'btn-reset dashboard-settings__btn dashboard-settings__btn--filled dashboard-settings__btn--disabled'}>Apply</button>
            </div>
        </div>
    )
}

export default Settings;