import React, { useState } from "react";

import video from '../../assets/img/video.jpg';

import playBtn from '../../assets/svg/play-btn.svg';

import './Presentation.scss'

const Presentation: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {
    const renderBoldText = (text: string) => {
        const parts = text.split(/\*\*(.*?)\*\*/g);
        return parts.map((part, i) => {
            return i % 2 === 0 ? part : <b key={i}>{part}</b>;
        });
    };

    const [videoActive, setVideoActive] = useState(false)

    return (
        <section ref={refProp} className="presentation">
            <div className="container flex">
                <div className="presentation__left">
                    <span className="presentation__article">{t('presentation.article')}</span>
                    <h2 className="presentation__title">{t('presentation.title')}</h2>
                    <p className="presentation__descr">{renderBoldText(t('presentation.descr'))}</p>
                </div>
                <div className="presentation__right">
                    {!videoActive ? (
                        <div className="video-container">
                            <img onClick={() => setVideoActive(true)} src={playBtn} alt="play video button" className="btn-reset video-btn" />
                            <img src={video} alt="preview img for video" className="presentation__img" />
                        </div>
                    ) : (
                        <div>
                            <iframe
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                src="https://www.youtube.com/embed/FseCB9lHwoM?si=eltJTZ8EGNSnlc20&autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                                referrerPolicy="strict-origin-when-cross-origin">
                            </iframe>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default Presentation;