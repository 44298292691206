import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCredentials } from './../../api/private/getCredentials';
import { changeCredsAPI } from "../../api/private/changeCreds";


type credsArgs = Record<string, string>;

export const getCreds: any = createAsyncThunk(
    'login/fetchGetCredetials',
    async () => {
        const data = await getCredentials();
        return data;
    }
)

export const changeCreds: any = createAsyncThunk(
    'login/fetchChangeCredetials',
    async (params: credsArgs, { rejectWithValue }) => {
        const {
            email,
            password,
            otpCode,
            emailCode
        } = params;
        try {
            const data = await changeCredsAPI(email, password, otpCode, emailCode);
            return data;
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data);
        }
    }
)


interface IAccountSettings {
    email: string,
    password: string,
    otpCode: string,
    emailCode: string,
    error: any,

    newEmail: string,
    newPassword: string,
    newSecondPassword: string,
}

const initialState: IAccountSettings = {
    email: '',
    password: '',
    otpCode: '',
    emailCode: '',
    error: [],
    newEmail: '',
    newPassword: '',
    newSecondPassword: ''
}

export const accountSlice = createSlice({
    name: 'accountSettings',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setOtpCode: (state, action) => {
            state.otpCode = action.payload;
        },
        setEmailCode: (state, action) => {
            state.emailCode = action.payload;
        },
        setCredsChangeError: (state, action) => {
            state.error = action.payload;
        },
        setNewEmail: (state, action) => {
            state.newEmail = action.payload;
        },
        setNewPassword: (state, action) => {
            state.newPassword = action.payload;
        },
        setNewSecondPassword: (state, action) => {
            state.newSecondPassword = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCreds.pending, (state) => {
        })
        builder.addCase(getCreds.fulfilled, (state, action) => {
            state.email = action.payload.payload.email;
            state.password = action.payload.payload.password;
        })
        builder.addCase(getCreds.rejected, (state) => {
        })
    },
})

export const { setEmail, setPassword, setNewSecondPassword, setNewEmail, setNewPassword, setOtpCode, setEmailCode, setCredsChangeError } = accountSlice.actions;

export default accountSlice.reducer