import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingLayout from './layouts/landingLayout/LandingLayout';
import Auth from './pages/auth/Auth';
import AuthLayout from './layouts/authLayout/AuthLayout';
import Verify from './pages/verify/Verify';
import Confirm from './pages/confirm/Confirm';
import DashboardLayout from './layouts/dashboardLayout/DashboardLayout';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import Notifications from './pages/notifications/Notifications';
import Subscriptions from './pages/subscriptions/Subscriptions';


import './App.scss';
import SettingsPage from './pages/settingsPage/SettingsPage';
import ChooseSubs from './pages/chooseSub/ChooseSubs';
import TelegramBot from './pages/telegram/TelegramBot';
import { SnackbarProvider, closeSnackbar } from 'notistack';

import CloseIcon from './assets/svg/dashboard/close--filled.svg';
//@ts-ignore
import { Helmet } from "react-helmet";
//@ts-ignore
import favicon from './assets/favicon/favicon.ico'

const App = () => {

  return (
    <SnackbarProvider
      hideIconVariant
      //autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={(snackbarId) => (
        <img onClick={() => closeSnackbar(snackbarId)} src={CloseIcon} alt="close confirm block"
          className='snackbar-close'
        />
      )
      }>
      <div className="site-container">
      <Helmet>
        <title>KairosPeak - AI Arbitrage Scanner</title>
          <meta name="description"
                content="Kairospeak’s AI-powered arbitrage scanner and screener instantly detects profitable crypto arbitrage opportunities, helping you optimize cryptocurrency trading with automated alerts for price differences across multiple exchanges."/>
          <meta name="keywords"
                content="arbitrage scanner, arbitrage screener, crypto arbitrage, crypto arbitrage scanner, cryptocurrency arbitrage opportunities, AI arbitrage, automated crypto trading, real-time arbitrage alerts, crypto trading optimization, blockchain arbitrage, arbitrage trading software, crypto arbitrage opportunities, crypto arbitrage bot, best arbitrage scanner"/>
          <meta property="og:title" content="KairosPeak - AI Arbitrage Scanner"/>
          <meta property="og:description" content="Crypto Arbitrage"/>
          <meta property="og:image" content={favicon}/>
      </Helmet>
          <BrowserRouter basename={'/'}>
          <Routes>
            <Route path='/' element={<LandingLayout />} />
            <Route element={<AuthLayout />}>
              <Route path='/login' element={<Auth />} />
              <Route path='/verify' element={<Verify />} />
              <Route path='/confirm' element={<Confirm />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path='/choosesub' element={<ChooseSubs />} />
              <Route path='/dashboard' element={<DashboardLayout />}>
                <Route path='' element={<Notifications />} />
                <Route path='subscriptions' element={<Subscriptions />} />
                <Route path='settings' element={<SettingsPage />} />
                <Route path='telegram-bot' element={<TelegramBot />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </SnackbarProvider >
  );
}

export default App;
