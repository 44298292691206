import React, { useState } from "react";
import { ReactComponent as Question } from './../../assets/svg/dashboard/question.svg'
import './Tooltip.scss'

interface ITooltip {
    inSettings?: boolean;
    tooltipArticle: string;
}

const Toopltip: React.FC<ITooltip> = ({ inSettings, tooltipArticle }) => {
    const [showDescr, setShowDescr] = useState(false);

    return (
        <>
            <Question onMouseEnter={() => setShowDescr(true)} onMouseLeave={() => setShowDescr(false)} className="dropdown__img" />
            {showDescr &&
                <div className={inSettings === true ? `tooltip tooltip--right` : 'tooltip'}>
                    <article className="tooltip__article">{tooltipArticle}</article>
                </div>}
        </>
    )
}

export default Toopltip;