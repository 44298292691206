import { $host } from "../index";
import { mockClients } from "../../utils/mockClients";

export const getActiveClients = async () => {
    const { data } = await $host.get('/api/general/info/exchanges', {
        headers: {
            Language: 'ru'
        }
    })
    if (!data.payload || data.payload.pairs.length < 500) {
        const data = mockClients;
        return { data }
    }
    return { data }
}