import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMessages } from '../../api/private/getMessages';
import { getIdentifiersAPI } from '../../api/private/getIdentifiersAPI';

enum typeConnect {
    Disconnected,
    Connected
}

export const getNotifications: any = createAsyncThunk(
    'login/fetchGetMessages',
    async (page: number) => {
        const data = await getMessages(page);
        return data;
    }
)

export const getIdentificators: any = createAsyncThunk(
    'websockets/fetchGetIds',
    async () => {
        const data = await getIdentifiersAPI();
        return data;
    }
)

interface WebSocketState {
    connect: typeConnect,
    time: string,
    notifications: [
        {
            id: string;
            active: boolean;
            gr: string;
            created: string;
            s: string;
            wb: string;
            ws: string;
            mp: number;
            vc: boolean;
            v: boolean;
            wda: boolean;
            wn: string;
            dn: string;
            alive: number;
            upd: number;
        }
    ] | [];
    pages: number;
    paginationPages: number;
    error: boolean;
    identificators: string[];
}

const initialState: WebSocketState = {
    connect: typeConnect.Disconnected,
    time: '',
    notifications: [],
    pages: 0,
    paginationPages: 0,
    error: false,
    identificators: []
}

const WebSocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        wsConnect(state) {
            state.connect = typeConnect.Connected
        },
        wsDisconnect(state) {
            state.connect = typeConnect.Disconnected
        },
        clearNotifications(state) {
            state.notifications = [];
            state.pages = 0;
        },
        setPaginationPages(state, action) {
            state.paginationPages = action.payload.selected;
        },
        setNotifications(state, action) {
            //@ts-ignore
            //state.notifications = [action.payload, ...state.notifications];
            let doublicateOfALL = state.identificators.find((item) => item === (action.payload.s + action.payload.wb + action.payload.ws));
            let doublicateOfState = state.notifications.find((item) => (item.s + item.wb + item.ws)  === (action.payload.s + action.payload.wb + action.payload.ws));
            if (doublicateOfALL) {
                let doublicateOfView = state.notifications.find((item) => (item.s + item.wb + item.ws) === (action.payload.s + action.payload.wb + action.payload.ws));
                if (doublicateOfView) {
                    if (JSON.parse(JSON.stringify(doublicateOfView)).created < action.payload.created) {
                        //@ts-ignore
                        state.notifications = state.notifications
                            .filter((item) => JSON.parse(JSON.stringify(item.s + item.wb + item.ws)) !== (action.payload.s + action.payload.wb + action.payload.ws));
                        //@ts-ignore
                        state.notifications = [action.payload, ...state.notifications]
                    }
                }
            } 
            else if (doublicateOfState) {
                    if (JSON.parse(JSON.stringify(doublicateOfState)).created < action.payload.created) {
                        //@ts-ignore
                        state.notifications = state.notifications
                            .filter((item) => JSON.parse(JSON.stringify(item.s + item.wb + item.ws)) !== (action.payload.s + action.payload.wb + action.payload.ws));
                        //@ts-ignore
                        state.notifications = [action.payload, ...state.notifications]
                    }
            }
            else {
                //@ts-ignore
                state.notifications = [action.payload, ...state.notifications];
            }
        },
        setTime(state, action) {
            state.time = action.payload;
        },
        setPage(state) {
            state.pages = state.pages + 1;
        },
        setError(state, action) {
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {

        //getIds
        builder.addCase(getIdentificators.fulfilled, (state, action) => {
            state.identificators = action.payload.payload;
        })

        //getSettings
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            //@ts-ignore
            //state.notifications = [...state.notifications, ...action.payload.payload.content];
            state.notifications = action.payload.payload.content;
        })
    }
})
export const { wsConnect, wsDisconnect, setNotifications, setTime, setPage, setError, clearNotifications, setPaginationPages } = WebSocketSlice.actions
export default WebSocketSlice.reducer