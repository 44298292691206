import React from "react";

import personalize from '../../assets/img/personalize.jpg';

import './Personalize.scss'
import { useNavigate } from "react-router";
//@ts-ignore
import Cookies from "js-cookie";

const Personalize: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {

    const list = t('personalize.list.items', { returnObjects: true });

    const navigate = useNavigate()

    const handleNavigate = () => {
        if (!Cookies.get('token')) {
            navigate('/login');
        } else {
            navigate('/dashboard/subscriptions')
        }
    }

    return (
        <section ref={refProp} className="personalize">
            <div className="container">
                <div className="personalize__article grid">
                    <h2 className="personalize__title">{t("personalize.title")}</h2>
                    <h3 className="personalize__subtitle">{t("personalize.subtitle")}</h3>
                </div>
                <div className="personalize__content grid">
                    <div className="img-wrapper">
                        <img src={personalize} width='1120px' height='619px' alt="interface app" className="personalize__img" />
                    </div>
                    <div className="personalize__right">
                        <p className="personalize__descr">{t("personalize.list.descr")}</p>
                        <ul className="list-reset personalize__list">
                            {list.map((item: any, index: any) => (
                                <li key={index} className="personalize__item">
                                    <p className="personalize__item__title"><b>{item.title}</b></p>
                                    <p className="personalize__item__article">{item.article}</p>
                                </li>
                            ))}
                        </ul>
                        <button onClick={handleNavigate} className="btn-reset btn personalize__btn">{t("personalize.button")}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Personalize;