import { createSlice } from "@reduxjs/toolkit";


interface ISnackbar {
    title?: string,
    typeAlet: string,
    message: string,
    active: boolean,
}

const initialState: ISnackbar = {
    title: '',
    typeAlet: '',
    message: '',
    active: false
}

export const snackbar = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setActive: (state, action) => {
            state.title = action.payload.title;
            state.active = action.payload.active;
            state.typeAlet = action.payload.typeAlet;
            state.message = action.payload.message;
        },
        clearSnackbar: (state) => {
            state.active = false;
            state.typeAlet = "";
            state.message = "";
        }
    }
})

export const { setActive, clearSnackbar } = snackbar.actions;

export default snackbar.reducer