import React, { useEffect, useState } from "react";

import './Filter.scss';
import DropDown from "../dropDown/DropDown";
import { useSelector } from "react-redux";
import { fetchGetInfos, setApplyedFilters, setBuyExchanges, setDateFrom, setDateTo, setDwAvailable, setGrades, setProfitFrom, setProfitTo, setActive, setSellExchanges, setTimeFrom, setTimeTo, setVerifyed } from "../../redux/slices/dashboardSlice";
import DatePicker from "../datePicker/DatePicker";
import { useDispatch } from "react-redux";
//@ts-ignore
import { socket } from "../../redux/middleware/websocketMiddleware";

import Toopltip from "../tooltip/Tooltip";
import { useTranslation } from "react-i18next";

import { enqueueSnackbar } from 'notistack';

const Filter = () => {

    const { t, i18n } = useTranslation<any>('')
    const dispatch = useDispatch()
    const grades = useSelector((state: any) => state.dashboard.grades);
    const buyExchanges = useSelector((state: any) => state.dashboard.buyExchanges);
    const sellExchanges = useSelector((state: any) => state.dashboard.sellExchanges);
    const profitFrom = useSelector((state: any) => state.dashboard.profitFrom.value);
    const profitTo = useSelector((state: any) => state.dashboard.profitTo.value);
    const dateFrom = useSelector((state: any) => state.dashboard.dateFrom);
    const dateTo = useSelector((state: any) => state.dashboard.dateTo);
    const verified = useSelector((state: any) => state.dashboard.verified);
    const dwAvailable = useSelector((state: any) => state.dashboard.dwAvailable);
    const active = useSelector((state: any) => state.dashboard.active);

    useEffect(() => {
        dispatch(fetchGetInfos())
    }, [])

    const [activelist, setActivelist] = useState<any>({
        active: false,
        id: null
    })

    function convertDate(dateString: string) {
        var p = dateString.split(/\D/g)
        return [p[2], p[1], p[0]].join("-")
    }

    const applyFilters = () => {
        dispatch(setApplyedFilters({
            ...(grades?.selected) && { ['Grades']: grades?.selected },
            ...(dateFrom.date) && { ['Date From']: convertDate(dateFrom.date) + " 00:00:00"},
            ...(dateTo.date) && { ['Date To']: convertDate(dateTo.date) + " 23:59:00" },
            ...(buyExchanges.selected) && { ['Buy Exchange']: buyExchanges.selected },
            ...(sellExchanges.selected) && { ['Sell Exchange']: sellExchanges.selected },
            ...(profitFrom) && { ['Profit From']: Number(profitFrom) },
            ...(profitTo) && { ['Profit To']: Number(profitTo) },
            ...(verified) && { ['Verified']: verified },
            ...(dwAvailable) && { ['Available']: dwAvailable },
            ...(active) && { ['Active']: active },
        }))
        socket.emit('filter', JSON.stringify({
            ...(grades?.selected) && { grade: grades?.selected },
            ...(dateFrom.date) && { from: convertDate(dateFrom.date) + "T" + "00:00" + ":" + "00" + '.000Z' },
            ...(dateTo.date) && { to: convertDate(dateTo.date) + "T" + "23:59" + ":" + "00" + '.000Z' },
            ...(buyExchanges.selected) && { buyExchange: buyExchanges.selected },
            ...(sellExchanges.selected) && { sellExchange: sellExchanges.selected },
            ...(profitFrom) && { profitFrom: Number(profitFrom) },
            ...(profitTo) && { profitTo: Number(profitTo) },
            ...(verified) && { verified: verified },
            ...(dwAvailable) && { dwAvailable: dwAvailable },
            ...(active) && { active: active },
        }),
            (response: any) => {
                // dispatch(setActive({
                //     active: true,
                //     typeAlet: '',
                //     message: response
                // }))
                if (!response.includes('Error', 0)) {
                    enqueueSnackbar(response, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(response, { variant: 'error' });
                }
            })
        //https://stackoverflow.com/questions/74155278/filter-for-array-of-objects-that-contain-all-key-value-pairs-of-one-object-in-an
    }

    const handleProfitFrom = (e: any) => {
        if (profitFrom.length <= 5 && e.target.value > 0 && e.target.value < 100) {
            let wordAfterDot = profitFrom.toString().split('.')[1] || '';
            if (e.target.value <= 100) {
                dispatch(setProfitFrom(e.target.value));
                if (wordAfterDot?.length < 2) {
                    dispatch(setProfitFrom(e.target.value));
                }
                else {
                    dispatch(setProfitFrom(profitFrom.slice(0, -1)))
                }
            }
        } else {
            dispatch(setProfitFrom(profitFrom.slice(0, -1)))
        }
    }

    const handleProfitTo = (e: any) => {
        // if (e.target.value <= 100) {
        //     dispatch(setProfitTo(e.target.value))
        // }
        if (profitTo.length <= 5 && e.target.value > 0) {
            let wordAfterDot = profitTo.toString().split('.')[1] || '';
            if (e.target.value <= 100) {
                dispatch(setProfitTo(e.target.value));
                if (wordAfterDot?.length < 2) {
                    dispatch(setProfitTo(e.target.value));
                }
                else {
                    dispatch(setProfitTo(profitTo.slice(0, -1)))
                }
            }
        } else {
            dispatch(setProfitTo(profitTo.slice(0, -1)))
        }
    }

    const sortGradesDescending = (a: string, b: string) => {
        const order = ['S', 'A', 'B', 'C', 'D', 'F'];
        return order.indexOf(a) - order.indexOf(b);
    };

    const sortAZ = (a: string, b: string): number => {
        return a.localeCompare(b);
    };

    return (
        <div className="filter">
            <div className="filter-left">
                <DropDown
                    id={1}
                    activelist={activelist}
                    setActivelist={setActivelist}
                    list={grades?.list}
                    selected={grades?.selected}
                    setIsSelected={setGrades}
                    article="Grade"
                    tooltipArticle={t("tooltips.toopltip1")}
                    sort={sortGradesDescending}
                />
                <DropDown
                    id={2} activelist={activelist}
                    setActivelist={setActivelist}
                    list={buyExchanges?.list}
                    selected={buyExchanges?.selected}
                    setIsSelected={setBuyExchanges}
                    article="Buy exchange"
                    tooltipArticle={t("tooltips.toopltip2")}
                    sort={sortAZ}
                />
                <DropDown
                    id={3}
                    activelist={activelist}
                    setActivelist={setActivelist}
                    list={sellExchanges?.list}
                    selected={sellExchanges?.selected}
                    setIsSelected={setSellExchanges}
                    article="Sell exchange"
                    tooltipArticle={t("tooltips.toopltip3")}
                    sort={sortAZ}
                />
                <DatePicker
                    id={4}
                    article={'Date from'}
                    activelist={activelist}
                    setActivelist={setActivelist}
                    setDate={setDateFrom}
                    setTime={setTimeFrom}
                    activeDay={dateFrom}
                    tooltipArticle={t("tooltips.toopltip4")}
                />
                <DatePicker
                    id={5}
                    article={'Date to'}
                    activelist={activelist}
                    setActivelist={setActivelist}
                    setDate={setDateTo}
                    setTime={setTimeTo}
                    activeDay={dateTo}
                    tooltipArticle={t("tooltips.toopltip5")}
                />
                <div className="filter-profit filter-profit--1">
                    <div className="dropdown__article">Profit from
                        <Toopltip tooltipArticle={t("tooltips.toopltip6")} inSettings={false} />
                    </div>
                    <div className="filter-profit__counter">
                        <input placeholder="0" value={profitFrom} onChange={(e) => handleProfitFrom(e)} pattern="^\d*(\.\d{0,2})?$" className="filter-profit__input" />
                    </div>
                </div>
                <div className="filter-profit filter-profit--2">
                    <div className="dropdown__article">Profit to
                        <Toopltip tooltipArticle={t("tooltips.toopltip7")} inSettings={false} />
                    </div>
                    <div className="filter-profit__counter">
                        <input placeholder="100" value={profitTo} onChange={(e) => handleProfitTo(e)} pattern='[0-9][0-9]' type="text" min={0} max={100} className="filter-profit__input" />
                    </div>
                </div>
            </div>
            <div className="filter-right">
                <div className="checkbox-group">
                    <label className="custom-checkbox">
                        <input checked={verified} onChange={() => dispatch(setVerifyed(!verified))} type="checkbox" className="custom-checkbox__field" />
                        <span className="custom-checkbox__title">
                            Verified
                            <Toopltip tooltipArticle={t("tooltips.toopltip8")} inSettings={true} />
                        </span>
                    </label>
                    <label className="custom-checkbox">
                        <input checked={dwAvailable} onChange={() => dispatch(setDwAvailable(!dwAvailable))} type="checkbox" className="custom-checkbox__field" />
                        <span className="custom-checkbox__title">
                            Available
                            <Toopltip tooltipArticle={t("tooltips.toopltip9")} inSettings={true} />
                        </span>
                    </label>
                    <label className="custom-checkbox">
                        <input checked={active} onChange={() => dispatch(setActive(!active))} type="checkbox" className="custom-checkbox__field" />
                        <span className="custom-checkbox__title">
                            Active
                            <Toopltip tooltipArticle={t("tooltips.toopltip9_2")} inSettings={true} />
                        </span>
                    </label>
                </div>
                <button onClick={applyFilters} className="btn-reset filter__btn filter__btn--filled">Filter</button>
            </div>
        </div>
    )
}

export default Filter;