import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSettings } from "../../api/private/getSettings";
import { setSettings } from "../../api/private/setSettings";
import { getInfosAPI } from "../../api/private/getInfos";


export const fetchGetSettings: any = createAsyncThunk(
    'dashboard/fetchGetSettings',
    async () => {
        const data = await getSettings();
        return data;
    }
)

export const fetchGetInfos: any = createAsyncThunk(
    'dashboard/fetchGetInfos',
    async () => {
        const data = await getInfosAPI();
        return data;
    }
)



interface ISettings {
    exchages: {},
    includeAllPossibles: false,
    includeBestNetworks: false,
    includeCommissionsC: false,
    includeCommissionsR: false,
    includeDepAndWithAvailableOnly: false,
    includeVerifiedByContractOnly: false,
    includeVerifiedOnly: false,
    minimalProfitPercent: number,
    usdtToSpend: number,
    firstLogin: boolean
    
}

export const fetchSetSettings: any = createAsyncThunk(
    'dashboard/fetchSetSettings',
    async (settings: ISettings) => {
        const data = await setSettings(settings);
        return data;
    }
)

interface IDashboard {
    settings: {
        exchages: {};
        includeAllPossibles: boolean;
        includeBestNetworks: boolean;
        includeCommissionsC: boolean;
        includeCommissionsR: boolean;
        includeDepAndWithAvailableOnly: boolean;
        includeVerifiedByContractOnly: boolean;
        includeVerifiedOnly: boolean;
        minimalProfitPercent: number;
        usdtToSpend: number;
        firstLogin: boolean
    },
    loading: boolean;
    //filter
    filterApplyed: boolean,
    grades: {
        list: string[];
        selected: string;
    },
    buyExchanges: {
        list: string[];
        selected: string;
    },
    sellExchanges: {
        list: string[];
        selected: string;
    },
    profitFrom: {
        value: string | number;
    },
    profitTo: {
        value: string | number;
    },
    dateFrom: {
        date: string;
        time: {
            hours: string;
            minutes: string
        }
    };
    dateTo: {
        date: string;
        time: {
            hours: string;
            minutes: string
        }
    };
    verified: boolean;
    dwAvailable: boolean;
    applyedFilters: {
        grades?: string;
        buyExchanges?: string;
    },
    active: boolean;
}

const initialState: IDashboard = {
    settings: {
        exchages: {},
        minimalProfitPercent: 0.1,
        includeAllPossibles: false,
        includeBestNetworks: false,
        includeCommissionsC: false,
        includeCommissionsR: false,
        includeDepAndWithAvailableOnly: false,
        includeVerifiedByContractOnly: false,
        includeVerifiedOnly: false,
        usdtToSpend: 500,
        firstLogin: false
    },
    loading: true,
    //filter
    filterApplyed: false,
    grades: {
        list: ['A+', 'A', 'B+', 'B', 'C+', 'C'],
        selected: ''
    },
    buyExchanges: {
        list: ['A+', 'A', 'B+', 'B', 'C+', 'C'],
        selected: '',
    },
    sellExchanges: {
        list: ['A+', 'A', 'B+', 'B', 'C+', 'C'],
        selected: ''
    },
    profitFrom: {
        value: ''
    },
    profitTo: {
        value: ''
    },
    dateFrom: {
        date: '',
        time: {
            hours: '00',
            minutes: '00'
        }
    },
    dateTo: {
        date: '',
        time: {
            hours: '00',
            minutes: '00'
        }
    },
    verified: false,
    dwAvailable: false,
    active: false,
    applyedFilters: {}
}

export const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setGrades: (state, action) => {
            state.grades.selected = action.payload;
        },
        setBuyExchanges: (state, action) => {
            state.buyExchanges.selected = action.payload;
        },
        setSellExchanges: (state, action) => {
            state.sellExchanges.selected = action.payload;
        },
        setProfitFrom: (state, action) => {
            state.profitFrom.value = action.payload;
        },
        setProfitTo: (state, action) => {
            state.profitTo.value = action.payload;
        },
        setDateFrom: (state, action) => {
            state.dateFrom.date = action.payload
        },
        setDateTo: (state, action) => {
            state.dateTo.date = action.payload
        },
        setTimeFrom: (state, action) => {
            if (!isNaN(action.payload.hours) && !isNaN(action.payload.minutes)) {
                state.dateFrom.time = action.payload
            }
        },
        setTimeTo: (state, action) => {
            if (!isNaN(action.payload.hours) && !isNaN(action.payload.minutes)) {
                state.dateTo.time = action.payload
            }
        },
        setVerifyed: (state, action) => {
            state.verified = action.payload;
        },
        setDwAvailable: (state, action) => {
            state.dwAvailable = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setApplyedFilters: (state, action) => {
            state.applyedFilters = action.payload
        },
        deleteFilter: (state, action) => {
            //@ts-ignore
            delete state.applyedFilters[action.payload]
            switch (action.payload) {
                case "Grades":
                    state.grades.selected = '';
                    break;
                case "Buy Exchange":
                    state.buyExchanges.selected = '';
                    break;
                case "Sell Exchange":
                    state.sellExchanges.selected = '';
                    break;
                case "Date From":
                    state.dateFrom.date = '';
                    state.dateFrom.time.hours = '';
                    state.dateFrom.time.minutes = '';
                    break;
                case "Date To":
                    state.dateTo.date = '';
                    state.dateTo.time.hours = '';
                    state.dateTo.time.minutes = '';
                    break;
                case "Profit From":
                    state.profitFrom.value = '';
                    break;
                case "Profit To":
                    state.profitFrom.value = '';
                    break;
                case "Verified":
                    state.verified = false;
                    break;
                case "Available":
                    state.dwAvailable = false;
                    break;
                case "Active":
                    state.active = false;
                    break;
                // case "Grades":
                //     state.grades.selected = '';
                // case "Grades":
                //     state.grades.selected = '';
                // case "Grades":
                //     state.grades.selected = '';
                // case "Grades":
                //     state.grades.selected = '';
            }
        },
        //settings reducers
        setExchanges: (state, action) => {
            state.settings.exchages = action.payload;
        },
        setMinimalProfit: (state, action) => {
            state.settings.minimalProfitPercent = Number(action.payload);
        },
        setUsdToSpend: (state, action) => {
            state.settings.usdtToSpend = Number(action.payload);
        },
        setComissionsC: (state, action) => {
            state.settings.includeCommissionsC = action.payload;
        },
        setAvailableExchange: (state, action) => {
            state.settings.includeDepAndWithAvailableOnly = action.payload;
        },
        setVerifiedOnly: (state, action) => {
            state.settings.includeVerifiedOnly = action.payload;
        },
        setBestNetwork: (state, action) => {
            state.settings.includeBestNetworks = action.payload;
        },
        setComissionsR: (state, action) => {
            state.settings.includeCommissionsR = action.payload;
        },
        setShowPossibles: (state, action) => {
            state.settings.includeAllPossibles = action.payload;
        },
        setVerifiedByContact: (state, action) => {
            state.settings.includeVerifiedByContractOnly = action.payload;
        }
    },
    extraReducers: (builder) => {
        //getSettings
        builder.addCase(fetchGetSettings.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchGetSettings.fulfilled, (state, action) => {
            state.settings = action.payload.payload;
            state.loading = false;
        })
        builder.addCase(fetchGetSettings.rejected, (state) => {
            state.loading = false;
        })
        //setSettings
        builder.addCase(fetchSetSettings.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchSetSettings.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(fetchSetSettings.rejected, (state) => {
            state.loading = false;
        })
        //GetInfos
        builder.addCase(fetchGetInfos.pending, (state) => {
        })
        builder.addCase(fetchGetInfos.fulfilled, (state, action) => {
            state.grades.list = action.payload.payload.grades;
            state.buyExchanges.list = action.payload.payload.exchanges;
            state.sellExchanges.list = action.payload.payload.exchanges;
            //state.settings.exchages = action.payload.payload.exchanges.reduce((acc: any, curr: any) => (acc[curr] = 'false', acc), {});
        })
        builder.addCase(fetchGetInfos.rejected, (state) => {
            //state.loading = false;
        })
    }
})

export const { setGrades, setBuyExchanges, setSellExchanges, setProfitFrom, setProfitTo, setDateFrom, setDateTo, setTimeFrom, setTimeTo, setVerifyed, setDwAvailable, setActive,
    setApplyedFilters, deleteFilter,
    setExchanges, setMinimalProfit, setUsdToSpend, setComissionsC, setVerifiedOnly, setBestNetwork, setComissionsR, setShowPossibles, setVerifiedByContact, setAvailableExchange } = dashboard.actions;

export default dashboard.reducer