import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import './DropDown.scss';

import Tooltip from "../tooltip/Tooltip";

interface IDropDown {
    list: string[];
    selected: string;
    setIsSelected: (item: string) => any;
    article: string;
    little?: boolean;
    activelist: any;
    setActivelist: (item: any) => any;
    id: number;
    tooltipArticle: string;
    sort?: (a: string, b: string) => number;
}

const DropDown: React.FC<IDropDown> = ({
                                           list,
                                           selected,
                                           setIsSelected,
                                           article,
                                           little,
                                           activelist,
                                           setActivelist,
                                           id,
                                           tooltipArticle,
                                           sort
                                       }) => {

    const dispatch = useDispatch();

    const sortedList = useMemo(() => {
        return sort ? [...list].sort(sort) : list;
    }, [list, sort]);

    const handleOnClickLabel = (e: any) => {
        const { textContent } = e.target;
        if (textContent) {
            dispatch(setIsSelected(textContent));
        }
        setActivelist(false);
    };

    const handleActiveList = (id: number) => {
        if (id === activelist.id) {
            setActivelist({ id: id, active: !activelist.active });
        } else {
            setActivelist({ id: id, active: true });
        }
    };

    return (
        <div className={little ? `dropdown dropdown--little dropdown--${id}` : `dropdown dropdown--${id}`}>
            <div className="dropdown__article">
                {article}
                <Tooltip tooltipArticle={tooltipArticle} inSettings={false} />
            </div>
            <div
                onClick={() => handleActiveList(id)}
                className={activelist.active && activelist.id === id ? "dropdown__btn dropdown__btn--active" : "dropdown__btn"}
            >
                {selected ? selected : "Select one"}
            </div>
            <ul className={activelist.active && activelist.id === id ? 'list-reset dropdown__list dropdown__list--active' : 'list-reset dropdown__list'}>
                {sortedList.map((item, index) => (
                    <li
                        key={index}
                        onClick={(e) => handleOnClickLabel(e)}
                        className="dropdown__item"
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DropDown;
