import { $authHost } from "../index";

interface ISettings {
    exchages: {},
    includeAllPossibles: false,
    includeBestNetworks: false,
    includeCommissionsC: false,
    includeCommissionsR: false,
    includeDepAndWithAvailableOnly: false,
    includeVerifiedByContractOnly: false,
    includeVerifiedOnly: false,
    minimalProfitPercent: number,
    usdtToSpend: number,
    firstLogin: boolean
}

export const setSettings = async (settings: ISettings) => {
    const { data } = await $authHost.post('/api/dashboard/set/settings', settings)
    return data;
}