import { Middleware } from "@reduxjs/toolkit";
//@ts-ignore
import io from 'socket.io-client';
import { setError, setNotifications, setTime } from "../slices/websocketSlice";

enum typeConnect {
    Disconnected,
    Connected
}

export let socket: any;
export const webSocketMiddleware: Middleware<{}, any> = store => next => action => {
    if (window.location.pathname === '/dashboard') {
        const webSocketState: any = store.getState().websocket;
        let identificaors: any = store.getState().websocket.identificators
        if (webSocketState.connect === typeConnect.Disconnected && !socket) {
            socket = io('', {
                withCredentials: true,
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            'Authorization': `Bearer ${store.getState().login.token}`,
                        }
                    }
                }
            }, { reconnection: false })
            socket.on('connect', () => {
                var timestamp = new Date();
                const offset = timestamp.getTimezoneOffset() * 60000; // milliseconds
                const local = new Date(timestamp.getTime() - offset);
                store.dispatch(setTime((local).toISOString().slice(0, 16).replace("T", " ")));
                store.dispatch(setError(false))
            })
            socket.on('update', store.dispatch(setError(false)))
            socket.on('dashboard', (data: any) => {
                var timestamp = new Date();
                const offset = timestamp.getTimezoneOffset() * 60000; // milliseconds
                const local = new Date(timestamp.getTime() - offset);
                store.dispatch(setTime((local).toISOString().slice(0, 16).replace("T", " ")));
                store.dispatch(setNotifications(JSON.parse(data)))
                store.dispatch(setError(false))
            })

            socket.on('connect_error', (error: any) => {
                store.dispatch(setError(true))
            })
        }
    }
    next(action)
}