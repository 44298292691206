import React, { useState } from "react";

import './datePicker.scss';
import { useDispatch } from "react-redux";
import Toopltip from "../tooltip/Tooltip";
import {setTimeFrom, setTimeTo} from "../../redux/slices/dashboardSlice";

interface IDatePicker {
    activelist: any;
    setActivelist: (item: any) => any;
    id: number;
    article: string;
    setDate: (date: any) => any;
    activeDay: any;
    setTime: (time: any) => any;
    tooltipArticle: string;
}

const DatePicker: React.FC<IDatePicker> = ({ id, article, activelist, setActivelist, setDate, activeDay, setTime, tooltipArticle }) => {



    const dispatch = useDispatch()
    const options: any = { month: 'long', day: 'numeric' }
    const prevDays = (offset: number) => {
        let d = new Date();
        d.setDate(d.getDate() - offset);
        return d;
    };

    const [activePicker, setActivePicker] = useState(false)
    const [showDescr, setShowDescr] = useState(false)


    const handleDate = (day: string) => {
        if (day) {
            dispatch(setDate(day));
            // dispatch(setTimeFrom({"hours": 0, "minutes": 0}))
            // dispatch(setTimeTo({"hours": 0, "minutes": 0}))
        }
        setActivePicker(false)
        setActivelist(false);
    };

    const handleActiveList = (id: number) => {
        if (id === activelist.id) {
            setActivelist({ id: id, active: !activelist.active })
        } else {
            setActivelist({ id: id, active: true })
        }
    }



    const year = new Date().getFullYear()
    const placeholderString = `Select date`

    return (
        <div className={`dropdown dropdown--datepicker dropdown--datepicker--${id}`}>
            <div className="dropdown__article">{article}
                <Toopltip tooltipArticle={tooltipArticle} />
            </div>
            <div onClick={() => handleActiveList(id)} className={activelist.active && activelist.id === id ? "dropdown__btn dropdown__btn--active" : "dropdown__btn"}>
                {activeDay.date ? activeDay.date : placeholderString}</div>
            <ul className={activelist.active && activelist.id === id ? 'list-reset dropdown__list dropdown__list--active' : 'list-reset dropdown__list'}>
                <li className="date-picker">
                    <div className="date-picker__content">
                        <div className="date-picker__left">
                            <span className="date-picker__text">Date</span>
                            <div
                                onClick={() => setActivePicker(!activePicker)}
                                className={activePicker ? "date-picker__btn date-picker__btn--active" : "date-picker__btn"}>
                                {activeDay.date ? activeDay.date : placeholderString}
                            </div>
                            <ul className={activePicker ? 'list-reset date-picker__list date-picker__list--active' : 'list-reset date-picker__list'}>
                                {/*<li className="date-picker__item" onClick={() => handleDate(prevDays(3).toLocaleDateString("ru-RU"))}>{prevDays(3).toLocaleDateString("en-US", options)}</li>*/}
                                <li className="date-picker__item" onClick={() => handleDate(prevDays(2).toLocaleDateString("ru-RU"))}>{prevDays(2).toLocaleDateString("en-US", options)}</li>
                                <li className="date-picker__item" onClick={() => handleDate(prevDays(1).toLocaleDateString("ru-RU"))}>{prevDays(1).toLocaleDateString("en-US", options)}</li>
                                <li className="date-picker__item" onClick={() => handleDate(prevDays(0).toLocaleDateString("ru-RU"))}>{prevDays(0).toLocaleDateString("en-US", options)}</li>
                                {/*<li className="date-picker__item" onClick={() => handleDate(prevDays(-1).toLocaleDateString("ru-RU"))}>{prevDays(-1).toLocaleDateString("en-US", options)}</li>*/}
                            </ul>
                        </div>
                        {/*<div className="date-picker__right">*/}
                        {/*    <span className="date-picker__text">Time</span>*/}
                        {/*    <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                        {/*        <input placeholder={'00'} value={activeDay.time.hours} className="date-picker__time" type="text" maxLength={2} onChange={(e) => dispatch(setTime({ ...activeDay.time, hours: e.target.value }))} />*/}
                        {/*        <input placeholder={'00'} value={activeDay.time.minutes} className="date-picker__time" type="text" maxLength={2} onChange={(e) => dispatch(setTime({ ...activeDay.time, minutes: e.target.value }))} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </li>
            </ul>
        </div>
    )
}
export default DatePicker;