import React, { useState } from "react";

import { useAppDispatch } from "../../redux/store";
import {
    clearForm,
    sendEmailCode,
    setError,
    setInvalidPasswords,
    setmfaUrl,
    setPassword,
    setPreToken,
    setStatus,
    toggleForm,
} from "../../redux/slices/registrationSlice";
import { useNavigate } from "react-router";

import LoginForm from "../../components/loginForm/LoginForm";

import RegistrationForm from "../../components/registrationForm/RegistrationForm";
import { useSelector } from "react-redux";

import './Auth.scss'
import Modal from "../../components/modal/Modal";
import { createPortal } from "react-dom";
import { setActive } from "../../redux/slices/snackbarSlice";
import { unwrapResult } from '@reduxjs/toolkit';
import CustomInput from "../../components/customInput/CustomInput";
import {
    fetchLogin,
    setEmail,
    setOtpCode,
    setRememberMe,
    setResetComparePassword,
    setResetEmailCode,
    setResetOtpCode,
    setResetPassword
} from "../../redux/slices/loginSlice";
import { resetPassAPI } from "../../api/public/resetPassAPI";
import { comparePass } from "../../utils/comparePass";
import { confrimResetPassAPI } from "../../api/public/confirmResetPassAPI";
import { setEmail as setRegEmail } from "../../redux/slices/registrationSlice";
import Switch from "../../components/switch/Switch";
import { validate } from "../../utils/validate";

import { enqueueSnackbar } from 'notistack';
import CloseIcon from "../../components/closeIcon/CloseIcon";

const Auth = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [modal, setModal] = useState(false);
    const [codeModal, setCodeModal] = useState(false)

    const [toggleModal, setToggleModal] = useState('creds');
    const [lastModal, setLastModal] = useState('');

    const { isLogin, error } = useSelector((state: any) => state.registration);
    const {
        email,
        resetPassword,
        resetComparePassword,
        resetMailCode,
        resetOtpCode,
        otpCode,
        password,
        rememberMe
    } = useSelector((state: any) => state.login);


    const resetPassToEmail = (email: string) => {
        resetPassAPI(email)
            .then((originalPromiseResult: any) => {
                enqueueSnackbar(originalPromiseResult.message ? originalPromiseResult.message : 'Credentials reset completed', { variant: 'success' });
                handleToggleModal('confirm')
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.payload[0].error ? e.response.data.payload[0].error : 'Wrong email', { variant: 'error' });
            })
    }

    const resetPass = () => {
        if (comparePass(resetPassword, resetComparePassword)) {
            dispatch(setInvalidPasswords(comparePass(resetPassword, resetComparePassword)))
            enqueueSnackbar('Password mismatch', { variant: 'error' });
            dispatch(setResetPassword(''))
            dispatch(setResetComparePassword(''))
        } else {
            confrimResetPassAPI(email, resetPassword, resetOtpCode, resetMailCode)
                .then(unwrapResult)
                .then((originalPromiseResult: any) => {
                    enqueueSnackbar(originalPromiseResult.message ? originalPromiseResult.message : 'Credentials reset completed', { variant: 'success' });
                    setModal(false)
                })
                .catch((data) => {
                    dispatch(setError(validate(data.response.data.payload)))
                    let errors = data.response.data.payload;
                    let message = data.response.data.message;
                    if (errors) {
                        for (let i = 0; i < errors.length; i++) {
                            enqueueSnackbar(errors[i].error, { variant: 'error' });
                        }
                    }
                    else if (message) {
                        enqueueSnackbar(message, { variant: 'error' });
                    }
                })
        }
    }


    const handleToggleModal = (modal: string): void => {
        setToggleModal(modal);
        if (modal === 'confirm') {
            setLastModal('confirm')
        }
    }

    const loginToApp = (e: any) => {
        e.preventDefault();
        dispatch(setStatus('loading'))
        dispatch(fetchLogin({ email, password, otpCode, rememberMe }))
            .unwrap()
            .then((originalPromiseResult: any) => {
                // setCodeModal(false)
                dispatch(setStatus(''))
                // setCodeModal(true)
                navigate('/dashboard')
            })
            .catch((rejectedValueOrSerializedError: any) => {
                if (rejectedValueOrSerializedError.status === 401) {
                    enqueueSnackbar(rejectedValueOrSerializedError.message ? rejectedValueOrSerializedError.message : 'Login failed', { variant: 'error' });
                    dispatch(setStatus(''));
                    // setCodeModal(false)
                } else if (rejectedValueOrSerializedError.status === 409) {
                    enqueueSnackbar(rejectedValueOrSerializedError.message ? rejectedValueOrSerializedError.message : 'Login failed', { variant: 'error' });
                    dispatch(setStatus(''));
                    // setCodeModal(false)
                } else {
                    enqueueSnackbar('Login failed. Unable to login', { variant: 'error' });
                    dispatch(setStatus(''));
                }
            })
    }

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            loginToApp(e)
        }
    };

    const validOtp = () => {
        return otpCode && otpCode.length == 6;
    }

    const closeModal = () => {
        setModal(false);
    }

    return (
        <>
            {!isLogin ? (
                <LoginForm setModal={setModal} setCodeModal={loginToApp} dispatch={dispatch} toggleForm={toggleForm} />
            ) : (
                <RegistrationForm dispatch={dispatch} toggleForm={toggleForm} navigate={navigate} />
            )}
            {modal && createPortal(
                <Modal>
                    {toggleModal === 'creds' ? (<div className="confirm creds-change__confirm">
                        {/* <img onClick={() => closeModal()} src={CloseIcon} alt="close confirm block"
                            className="confirm__img" /> */}
                        <CloseIcon functionClose={() => closeModal()} />
                        <h1 className="confirm__title confirm-form__title">Reset your password</h1>
                        <form onSubmit={(e) => e.preventDefault()} className="confirm-form">
                            <label>
                                <p className="confirm-form__article">Email:</p>
                                <div className="input-wrapper input-wrapper--login input-wrapper--confirm">
                                    <input value={email} onChange={(e) => dispatch(setEmail(e.target.value))}
                                        placeholder="Enter your email"
                                        className="confirm-form__input" type="text" />
                                </div>
                            </label>
                            <div className="creds-change__btns btn-group">
                                <button disabled={!email ? true : false} onClick={() => resetPassToEmail(email)}
                                    className={!email ? "btn-reset confirm__btn confirm__btn--filled confirm__btn--blocked" : "btn-reset confirm__btn confirm__btn--filled"}>Send
                                </button>
                                <button onClick={() => setModal(false)}
                                    className="btn-reset confirm__btn confirm__btn">Back
                                </button>
                            </div>
                        </form>
                    </div>) : <div
                        className={lastModal === 'confirm' ? "confirm creds-change__confirm creds-change--animation" : "confirm creds-change__confirm"}>
                        {/* <img onClick={() => setModal(false)} src={CloseIcon} alt="close confirm block"
                            className="confirm__img" /> */}
                        <CloseIcon functionClose={() => setModal(false)} />
                        <h1 className="creds-change__title">Credentials change</h1>
                        <div onSubmit={(e) => e.preventDefault()} className="confirm-form">
                            <label>
                                <p className="confirm-form__article">Email:</p>
                                <div className="input-wrapper input-wrapper--login input-wrapper--confirm">
                                    <input defaultValue={email} className="confirm-form__input" type="text" />
                                    {/* <button tabIndex={-1} className="btn-reset confirm-btn confirm-btn--enterCode"><span>Send</span></button> */}
                                </div>
                            </label>
                            <CustomInput
                                inputTitle="Enter new password"
                                inputValue={resetPassword}
                                setInputValue={setResetPassword} inputType="password"
                                error={error?.password}
                                passwordsCompare={error?.compare}
                                dispatch={dispatch} />
                            <CustomInput
                                inputTitle="Enter new password:"
                                inputValue={resetComparePassword}
                                setInputValue={setResetComparePassword} inputType="password"
                                error={error?.password}
                                passwordsCompare={error?.compare}
                                dispatch={dispatch} />
                            <CustomInput
                                inputTitle="Enter email code:"
                                inputValue={resetMailCode}
                                setInputValue={setResetEmailCode} inputType="login"
                                error={error?.code}
                                dispatch={dispatch} />
                            {/*<CustomInput*/}
                            {/*    inputTitle="Enter mobile authentificator code:"*/}
                            {/*    inputValue={resetOtpCode}*/}
                            {/*    setInputValue={setResetOtpCode} inputType="login"*/}
                            {/*    error={error?.otpCode}*/}
                            {/*    dispatch={dispatch} />*/}
                            {/* <CustomInput
                            inputTitle="Enter new password:"
                            inputValue={newPassword}
                            setInputValue={setNewPassword} inputType="password"
                            error={''}
                            dispatch={dispatch} /> */}
                        </div>
                        <div className="creds-change__btns btn-group">
                            <button onClick={() => resetPass()}
                                className="btn-reset confirm__btn confirm__btn--filled">Change password
                            </button>
                            <button onClick={() => handleToggleModal('creds')}
                                className="btn-reset confirm__btn confirm__btn">Back
                            </button>
                        </div>
                    </div>}
                </Modal>, document.querySelector('.auth-container')!)}
            {/*{codeModal && createPortal(*/}
            {/*    <Modal>*/}
            {/*    <div className="confirm creds-change__confirm">*/}
            {/*        <CloseIcon functionClose={() => setCodeModal(false)} />*/}
            {/*        <h1 className="confirm__title confirm-form__title">Verify your identity</h1>*/}
            {/*        <form onKeyDown={(e) => checkKeyDown(e)} onSubmit={(e) => loginToApp(e)} style={{ padding: '15px' }}*/}
            {/*            className="confirm-form">*/}
            {/*            <CustomInput*/}
            {/*                inputTitle="Code from 2FA app:"*/}
            {/*                inputValue={otpCode}*/}
            {/*                setInputValue={setOtpCode}*/}
            {/*                inputType="otpCode"*/}
            {/*                error={error?.otpCode}*/}
            {/*                dispatch={dispatch} />*/}
            {/*            <Switch active={rememberMe} setActive={setRememberMe} dispatch={dispatch} />*/}
            {/*            <div className="creds-change__btns btn-group">*/}
            {/*                <button type="submit" disabled={!validOtp()}*/}
            {/*                    className={!validOtp() ? "btn-reset confirm__btn confirm__btn--filled confirm__btn--blocked" : "btn-reset confirm__btn confirm__btn--filled"}>Send*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
            {/*    , document.querySelector('.auth-container')!)}*/}
        </>
    )
}

export default Auth;