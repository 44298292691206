import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import './Verify.scss';
import { useDispatch } from "react-redux";
import { setActive } from "../../redux/slices/snackbarSlice";

import { enqueueSnackbar } from 'notistack';


const Verify = () => {

    const { mfaUrl, mfaCode } = useSelector((state: any) => state.registration)
    const navigate = useNavigate()

    const dispatch = useDispatch()


    const copyToClipBoard = (mfaCode: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(mfaCode).then(() => {
                enqueueSnackbar('Code copied to clipboard', { variant: 'success' });
            })
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = mfaCode;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    }

    useEffect(() => {
        if (mfaUrl.length < 1) {
            navigate('/login')
        }
    }, [])



    return (
        <div className="verify">
            <h1 className="auth__title">Two factor authentication</h1>
            <img src={mfaUrl} className="auth__verify" alt="qr code" />
            <p className="auth__descr">Add this code with any authentication app.
                Here is an example: Google application</p>
            <div className="alter-code">
                <p className="alter-code__descr">Or add it using the code below:</p>
                <div onClick={() => copyToClipBoard(mfaCode)} className="alter-code__input">
                    <input type="text" value={mfaCode} />
                </div>
            </div>
            <div className="btn-group">
                <button onClick={() => navigate('/confirm')} className="btn-reset auth__btn auth__btn--filled">Continue</button>
                <button onClick={() => navigate('/login')} className="btn-reset auth__btn auth__btn">Back</button>
            </div>
        </div>
    )
}

export default Verify;