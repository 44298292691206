import React, { useEffect } from "react";

import { ReactComponent as SpinnerSvg } from './../../assets/svg/spinner.svg'
//import spinnerSvg from './../../assets/svg/spinner.svg'

import './LoadingSpinner.scss'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setStatus } from "../../redux/slices/registrationSlice";
import CloseIcon from './../../assets/svg/dashboard/close--filled.svg';

const Spinner = (): any => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={148} height={148} viewBox="0 0 24 24"><g stroke="#d4d5de"><circle cx={12} cy={12} r={9.5} fill="none" strokeLinecap="round" strokeWidth={1.95}><animate attributeName="stroke-dasharray" calcMode="spline" dur="2.25s" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" keyTimes="0;0.475;0.95;1" repeatCount="indefinite" values="0 150;42 150;42 150;42 150"></animate><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2.25s" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" keyTimes="0;0.475;0.95;1" repeatCount="indefinite" values="0;-16;-59;-59"></animate></circle><animateTransform attributeName="transform" dur="3s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform></g></svg>
    )
}

const Cross = (): any => {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 115L60 60L115 115M115 5L59.9895 60L5 5" stroke="white" stroke-width="9" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

const Accept = (): any => {
    return (
        <svg width="162" height="118" viewBox="0 0 162 118" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 62.4177L52.5 113L157 5" stroke="white" stroke-width="9" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

const LoadingSpinner = () => {

    const { status } = useSelector((state: any) => state.registration)
    const dispatch = useDispatch()

    useEffect(() => {
        if (status !== 'loading') {
            setTimeout(() => dispatch(setStatus('')), 2000)
        }
    }, [status])

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <div className="spinner-container spinner-container--loading">
            <img onClick={() => refreshPage()} src={CloseIcon} alt="close confirm block"
                className="confirm__img" />
            <div className="svg-container">
                {/* <article className="spinner__article">{article}</article> */}
                {/* <img className="spinner__img" src={spinnerSvg} alt="loading" /> */}
                {status === 'loading' && <div className="spinner">
                    <Spinner />
                </div>}
                {status === 'error' && <div className="cross">
                    <article className="cross__article">Declined!</article>
                    <Cross />
                </div>}
                {status === 'success' && <div className="accept">
                    <article className="accept__article">Accepted</article>
                    <div className="checkmark draw"></div>
                </div>}
            </div>
        </div>
    )
}

export default LoadingSpinner;