import React from "react";

import './Navigation.scss';
import { useLocation, useNavigate } from "react-router";

const Navigation = ({ setMenuActive, menuActive }: any) => {

    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className="navigation">
            <nav className="nav flex navigation__nav">
                <ul className="list-reset navigation__list flex">
                    <li onClick={() => navigate('/', { state: { targetId: '.home' } })} className="navigation__item">Home</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.info' } })} className="navigation__item">About</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.features' } })} className="navigation__item">Features</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.options' } })} className="navigation__item">Preferences</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.personalize' } })} className="navigation__item">Reliability</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.presentation' } })} className="navigation__item">Guide</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.connections' } })} className="navigation__item">Connections</li>
                    <li onClick={() => navigate('/', { state: { targetId: '.rates' } })} className="navigation__item">Tariffs</li>
                    <li>
                        {location.pathname === '/dashboard' && <div className="burger-menu-wrapper">
                            <div onClick={() => setMenuActive(!menuActive)} className={menuActive ? 'burger-menu burger-menu--open' : 'burger-menu'}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navigation;