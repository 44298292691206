
import React, { useEffect, useState } from "react";

import './Header.scss';


import logo from '../../assets/svg/kairospeak-logo.svg';
import login from '../../assets/svg/login.svg';
import arrow from '../../assets/svg/arrow-down.svg';
import { useNavigate } from "react-router";
//@ts-ignore
import Cookies from 'js-cookie';

interface IHeader {
    navbarRefs: {
        home: React.RefObject<HTMLDivElement>,
        about: React.RefObject<HTMLDivElement>,
        features: React.RefObject<HTMLDivElement>,
        options: React.RefObject<HTMLDivElement>,
        preferences: React.RefObject<HTMLDivElement>,
        faq: React.RefObject<HTMLDivElement>,
        connections: React.RefObject<HTMLDivElement>,
        tarrifs: React.RefObject<HTMLDivElement>
    },
    scrollTo: (ref: any) => void;
    changeLang: (lang: string) => void;
    t: any;
}

const Header = ({ navbarRefs, scrollTo, changeLang, t }: IHeader) => {

    const navigate = useNavigate()

    const [lang, setLang] = useState('en');

    const [openList, setOpenList] = useState(false)

    const handleOptions = (lang: string): any => {
        //setLang(e.currentTarget.value);
        //changeLang(e.currentTarget.value)
        setLang(lang);
        changeLang(lang)
        setOpenList(false)
        localStorage.setItem('currentLang', lang)
    }

    return (
        <header className='header'>
            <div className="container flex header__container">
                <a href="/#" className="logo">
                </a>
                <nav className='nav flex'>
                    <ul className="list-reset nav__list flex">
                        <li onClick={() => scrollTo(navbarRefs.about)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item2')}</a></li>
                        <li className="nav__item nav__item--clear"><span className="nav__link nav__link--line" aria-hidden="true"></span></li>
                        <li onClick={() => scrollTo(navbarRefs.features)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item3')}</a></li>
                        <li className="nav__item nav__item--clear"><span className="nav__link nav__link--line" aria-hidden="true"></span></li>
                        <li onClick={() => scrollTo(navbarRefs.options)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item4')}</a></li>
                        <li className="nav__item nav__item--clear"><span className="nav__link nav__link--line" aria-hidden="true"></span></li>
                        <li onClick={() => scrollTo(navbarRefs.preferences)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item5')}</a></li>
                        <li className="nav__item nav__item--clear"><span className="nav__link nav__link--line" aria-hidden="true"></span></li>
                        <li onClick={() => scrollTo(navbarRefs.faq)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item6')}</a></li>
                        <li className="nav__item  nav__item--clear"><span className="nav__link nav__link--line" aria-hidden="true"></span></li>
                        <li onClick={() => scrollTo(navbarRefs.connections)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item7')}</a></li>
                        <li className="nav__item nav__item--clear"><span className="nav__link nav__link--line" aria-hidden="true"></span></li>
                        <li onClick={() => scrollTo(navbarRefs.tarrifs)} className="nav__item"><a href="/#" className="nav__link">{t('header.navbar.item8')}</a></li>
                    </ul>
                </nav>
                <div className='header__right header__settings'>
                    {/* <select value={lang} onChange={handleOptions} name="" id="" className="header__select">
                        <option value='en'>en</option>
                        <option value='ru'>ru</option>
                    </select> */}
                    {/* <div onClick={() => setOpenList(!openList)} className="custom-select">
                        {openList ? (
                            <ul className="list-reset lang-list">
                                <li onClick={() => handleOptions('en')} className="lang-list__item">en</li>
                                <li onClick={() => handleOptions('ru')} className="lang-list__item">ru</li>
                            </ul>
                        ) : (
                            <div>
                                <span className="custom-select__selected">{lang}</span>
                            </div>
                        )
                        }
                        <img onClick={() => setOpenList(!openList)} className={openList ? 'arrow arrow--reversed' : 'arrow'} src={arrow} alt='open language list' />
                    </div> */}
                    <button onClick={() => navigate('/login')} className="btn-reset header__button">{!Cookies.get('token') ? t('header.login') : t('header.toAccount')}</button>
                    <img onClick={() => navigate('/login')} src={login} alt="login" className="header__login" />
                </div>
            </div>
        </header>
    )
}

export default Header;