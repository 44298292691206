import React, { useState, useEffect } from "react";
//@ts-ignore
import Cookies from 'js-cookie';
import kaisospeakLogo from '../../assets/svg/dashboard/logoicon.svg';
import { useNavigate} from "react-router";
import { useDispatch } from "react-redux";
import { setClearState } from "../../redux/slices/loginSlice";

import './MobileHeader.scss';
import { useTour } from "@reactour/tour";

const MobileHeader = ({ setSettingsToggle, setFilterToggle, filterToggle }: any) => {



    const [headeMenuActive, setHeaderMenuActive] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setIsOpen } = useTour();

    //@ts-ignore
    useEffect(() => {
        if (headeMenuActive) {
            document.body.style.overflow = 'hidden';
        }
        return () => document.body.style.overflow = 'unset';
    }, [headeMenuActive])


    const handleNavigate = (link: string) => {
        setHeaderMenuActive(false)
        navigate(link)
    }

    const logOut = () => {
        dispatch(setClearState());
        //localStorage.setItem('token', '');
        Cookies.remove('token')
        navigate('/login');
    }


    const handleTour = () => {
        setFilterToggle(true);
        setHeaderMenuActive(false);
        setSettingsToggle(true);
        setIsOpen(true);
        setFilterToggle(true);
    }

    return (
        <>
            <div className="mobile-header">
                <img src={kaisospeakLogo} alt="kaisospeak logo" className="mobile-header__img" onClick={() => navigate('/')} />
                <div className="burger-menu-wrapper">
                    <div onClick={() => setHeaderMenuActive(!headeMenuActive)} className={headeMenuActive ? 'burger-menu burger-menu--open' : 'burger-menu'}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            {headeMenuActive && <div>
                <div className="mobile-navigation">
                    <span className="blur"></span>
                    <ul className="list-reset mobile-nav">
                        <li onClick={() => handleNavigate('.')} className="mobile-nav__item">Notifications</li>
                        <li onClick={() => handleNavigate('./subscriptions')} className="mobile-nav__item">Subscriptions</li>
                        <li onClick={() => handleNavigate('./settings')} className="mobile-nav__item">Settings</li>
                        <li onClick={() => handleNavigate('./telegram-bot')} className="mobile-nav__item">Telegram bot</li>
                        <li className="mobile-nav__guide">
                            <div className="app-guide app-guide--mobile-menu" onClick={handleTour}>
                                <span className="app-guide__btn">?</span>
                            </div>
                        </li>
                        <li onClick={() => logOut()} className="mobile-nav__item">Log Out</li>
                    </ul>
                </div>
            </div>}
        </>
    )
}

export default MobileHeader;