import { combineReducers, Middleware } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import registrationReducer from './slices/registrationSlice';
import loginReducer from './slices/loginSlice';
import snackbarReducer from './slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import dashboardReducer from './slices/dashboardSlice';
import websocketReducer from './slices/websocketSlice';
import { webSocketMiddleware } from './middleware/websocketMiddleware';
import subscriptionReducer from './slices/subscriptionSlice';
import accountSettingsReducer from './slices/accountSettings';


const combinedReducer = combineReducers({
    registration: registrationReducer,
    login: loginReducer,
    snackbar: snackbarReducer,
    dashboard: dashboardReducer,
    websocket: websocketReducer,
    subscriptions: subscriptionReducer,
    accountSettings: accountSettingsReducer
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'RESET_STATE') { //We are calling this RESET, but call what you like!
        state = {};
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    //@ts-ignore
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([webSocketMiddleware]);
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()