import React from "react";

import infoimg from '../../assets/img/info-img.png';

import './Info.scss';


const Info: React.FC<{ refProp: React.RefObject<HTMLDivElement>, t: any }> = ({ refProp, t }: any) => {

    const renderBoldText = (text: string) => {
        const parts = text.split(/\*\*(.*?)\*\*/g);
        return parts.map((part, i) => {
            return i % 2 === 0 ? part : <b key={i}>{part}</b>;
        });
    };

    return (
        <section ref={refProp} className="info">
            <div className="container">
                <div className="info__content flex">
                    <div className="info__left">
                        <span className="info__article">{t("info.article")}</span>
                        <h2 className="info__title">{t("info.title")}</h2>
                        <h2 className="info__subtitle">{t("info.subtitle")}</h2>
                        <p className="info__descr">
                            <p className="info__descr_part">{renderBoldText(t("info.descr_pt1"))}</p>
                            <p className="info__descr_part">{renderBoldText(t("info.descr_pt2"))}</p>
                            <p className="info__descr_part">{renderBoldText(t("info.descr_pt3"))}</p>
                            <p className="info__descr_part">{renderBoldText(t("info.descr_pt4"))}</p>
                        </p>
                    </div>
                    <div className="info__right">
                        <img src={infoimg} width="736px" height="471px" alt="crypto arbitrage" className="info__img" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Info