import React, {useEffect, useRef, useState} from "react";

import {useTranslation} from 'react-i18next';

import Hero from '../../components/hero/Hero'
import Header from '../../components/header/Header';
import Info from '../../components/info/Info';
import Features from '../../components/features/Features';
import Options from '../../components/options/Options';
import Personalize from '../../components/personalize/Personalize';
import Presentation from '../../components/presentation/Presentation';
import Connections from '../../components/connections/Connections';
import Rates from '../../components/rates/Rates';
import Footer from '../../components/footer/Footer';
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import WebsiteLoading from "../../components/websiteLoading/WebsiteLoading";

const LandingLayout = () => {

    const {t, i18n} = useTranslation<any>('')
    const {state} = useLocation();
    const {targetId} = state || {};

    const changeLang = (lang: string): void => {
        i18n.changeLanguage(lang)
    }

    const navbarRefs = {
        home: useRef<HTMLDivElement | null>(null!),
        about: useRef<HTMLDivElement | null>(null!),
        features: useRef<HTMLDivElement | null>(null!),
        options: useRef<HTMLDivElement | null>(null!),
        preferences: useRef<HTMLDivElement | null>(null!),
        faq: useRef<HTMLDivElement | null>(null!),
        connections: useRef<HTMLDivElement | null>(null!),
        tarrifs: useRef<HTMLDivElement | null>(null!),
    }

    const scrollTo = (ref: any): void => {
        if (ref && ref.current /* + other conditions */) {
            ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
    }

    useEffect(() => {
        const el: any = document.querySelector(targetId);
        if (el) {
            el.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
    }, [targetId]);

    const {status} = useSelector((state: any) => state.registration)

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        window.onload = () => {
            setIsPageLoaded(true);
        };
    }, []);

    return (
        <div className="landing-container">
            {/*{!isPageLoaded ? <WebsiteLoading/> : null}*/}
            <Header navbarRefs={navbarRefs} scrollTo={scrollTo} changeLang={changeLang} t={t}/>
            <Hero t={t} scrollTo={scrollTo} tariffs={navbarRefs.tarrifs}/>
            <Info refProp={navbarRefs.about} t={t}/>
            <Features refProp={navbarRefs.features} t={t}/>
            <Options refProp={navbarRefs.options} t={t}/>
            <Personalize refProp={navbarRefs.preferences} t={t}/>
            <Presentation refProp={navbarRefs.faq} t={t}/>
            <Connections refProp={navbarRefs.connections} t={t}/>
            <Rates refProp={navbarRefs.tarrifs} t={t}/>
            <Footer t={t}/>
            {/*{status !== '' && isPageLoaded ? <WebsiteLoading/> : null}*/}
        </div>
    )
}

export default LandingLayout;