import React from "react";
import Navigation from "../../components/navigation/Navigation";

import Content from './../../assets/img/content-coming-soon.png';
import Sidebar from './../../assets/img/sidebar-cooming-soon.png';
import SidebarWide from './../../assets/img/sidebar-coming-soon-wide.png';

import MobileContent from './../../assets/img/mobile-coming-soon.png';

import './TelegramBot.scss';
import useWindowDimensions from "../../hooks/useWindowDimensions";

const TelegramBot = () => {

    const { width } = useWindowDimensions()

    return (
        <>
            <div className="telegram-bot-page">
                {/* <Navigation /> */}

                {width > 900 ? (
                    <>
                        <div className="telegram-bot__content">
                            <img src={Content} alt="coming-soon" />
                        </div>
                        <div className="telegram-bot-sidebar">
                            <img src={width > 1760 ? Sidebar : SidebarWide} alt="coming-soon" />
                        </div>
                    </>
                ) : (
                    <div className="telegram-bot__content mobile-content">
                        <img src={MobileContent} alt="coming-soon" />
                    </div>
                )}
            </div>
        </>
    )
}

export default TelegramBot;